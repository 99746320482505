import React from "react";

const Section7 = () => {

	return (
		<div id="kyc-process-section_1-step7" class="container-lg d-none">
			<div class="row no-gutters">
				<div class="col-lg-10 offset-lg-1 col-12">
					<div class="kyc-process-outer-card py-md-9 px-lg-9 p-3 mx-3 has-box-shadow text-center">
						<div class="kyc-process-inner-card py-md-5 px-lg-5 p-4 mx-md-3 mx-0 has-box-shadow text-center">
							<div class="row">
								<div class="col-lg-4 col-md-6 offset-lg-8 offset-md-6 col-12 d-flex">
									<select class="form-control secondary-select">
										<option value=''>English</option>
										<option>Arabic</option>
									</select>
								</div>
							</div>
							<div class="row my-8">
								<div class="col-lg-12 col-12 d-flex align-items-center justify-content-center">
									<div class="kyc-process-completion">
										<img alt="" src="/images/icon-kyc-process-verification-successful.svg"/>
										<p class="font-weight-bold">Verification Successful</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  
	);
	
	
};
export default Section7;