import {
  USER_DATA_SAVE,
  SAVE_TOKEN,
  REMOVE_USER_DATA,
  SAVE_CONFIG_DATA,
  LOGOUT,
  SAVE_LANGUAGE,
  PROPERTYLIST,
  SAVE_FILTER,
  SAVE_PRODUCTS,
  SAVE_VARIFY_TOKEN,
  REMOVE_VARIFY_TOKEN,
} from './types';
// import {defaultOptions} from "../../config";
import {aqarChain} from '../../aqarchain';
// import {store} from "../store";
// let language = store.getState("app").app.language;

export const saveUserDataLogin = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/auth/login', data);
    if (response.status === 1) {
      dispatch({type: USER_DATA_SAVE, payload: response.data});
      dispatch({type: SAVE_TOKEN, token: response.data.token});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

// property

export const getPropertyList = () => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.get('/api/property/');
    if (response.status === 1) {
      dispatch({type: PROPERTYLIST, payload: response.data});
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};

export const getPropertyDetails = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.get('/api/property/' + data);
    if (response.status === 1) {
      //dispatch({type: PROPERTYLIST, payload: response.data});
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};

export const searchProperty = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    //console.log('dddd', data);
    const response = await aqarChain.post('/api/property/search', data);
    //console.log('data', response);
    if (response.status === 1) {
      dispatch({type: SAVE_PRODUCTS, payload: response.data});
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};

export const searchBasic = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    //console.log('dddd', data);
    const response = await aqarChain.post('/api/property/search', data);
    //console.log('data', response);
    if (response.status === 1) {
      dispatch({type: SAVE_PRODUCTS, payload: response.data});
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};

export const getInTouch = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/auth/get/in/touch', data);
    console.log('data', response);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};

export const logOut = () => dispatch =>  dispatch({ type: LOGOUT});

export const removeUserData = data => async dispatch => {
  dispatch({type: REMOVE_USER_DATA});
};

export const getConfigData = () => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.get('/api/config/');
    // console.log('get config response', response);
    if (response.status === 1) {
      dispatch({type: SAVE_CONFIG_DATA, payload: response.data});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

export const getCountryData = () => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.get('/api/property/all/countries/');
    if (response.status === 1) {
      dispatch({type: 'COUNTRY_DATA', payload: response.data});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

export const getStateData = data => async dispatch => {
  const response = await aqarChain.post(
    '/api/property/country/wise-state/',
    data,
  );
  if (response.status === 1) {
    let stateData = [];
    stateData = await response.data.map(
      ({id: value, name: label, ...rest}) => ({value, label, ...rest}),
    );
    dispatch({type: 'STATE_DATA', payload: stateData});
  } else {
    console.log(error);
  }
};

export const getCityData = data => async dispatch => {
  const response = await aqarChain.post('/api/property/state/wise-city/', data);
  if (response.status === 1) {
    let cityData = [];
    cityData = await response.data.map(({id: value, name: label, ...rest}) => ({
      value,
      label,
      ...rest,
    }));
    dispatch({type: 'CITY_DATA', payload: cityData});
  } else {
    console.log(response.message);
  }
};

export const loading = bool => ({
  type: 'LOADING',
  isLoading: bool,
});

export const error = error => ({
  type: 'ERROR',
  error,
});

export const saveLanguage = language => ({
  type: SAVE_LANGUAGE,
  payload: language,
});

export const saveFilter = data => ({
  type: SAVE_FILTER,
  payload: data,
});

export const resetFilters = () => ({
  type: 'RESET_FILTER',
});

export const viewType = data => ({
  type: 'VIEW_TYPE',
  payload: data,
});

export const sendOtp = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post(
      '/api/auth/user/registration/send-otp/',
      data,
    );
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

export const varifyOtp = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post(
      '/api/auth/user/registration/verify-otp/',
      data,
    );
    if (response.status === 1) {
      dispatch({type: SAVE_VARIFY_TOKEN, payload: response.data.token});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

export const registerUser = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/auth/register/', data);
    //console.log("registration", response);
    if (response.status === 1) {
      resolve(response);
      dispatch({type: REMOVE_VARIFY_TOKEN});
    } else {
      reject(response.message);
    }
  });
};

export const companyRegistration = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.multipart(
      '/api/company/regitration',
      data,
    );
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response);
    }
  });
};

export const getWebAccessToken = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    console.log(data);
    const response = await aqarChain.post('/api/token/getToken', data);
    console.log('data', response);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

export const getWebAccessTokenIndividual = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    console.log(data);
    const response = await aqarChain.post('api/token/getTokenIndividual', data);
    console.log('data', response);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

export const varifyWebAccessToken = data => async dispatch => {
  console.log('token data check ----------', data);
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/token/verifyToken', data);
    console.log('varify response', response);
    if (response.status === 1) {
      dispatch(saveaccessCode(response.data.token));
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

export const regenerateWebAccessToken = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    console.log(data);
    const response = await aqarChain.post('/api/token/regenrateToken', data);
    console.log('data', response);
    if (response.status === 1) {
      dispatch(saveaccessCode(response.data));
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};

export const saveaccessCode = data => ({
  type: 'SAVE_ACCESS_CODE',
  payload: data,
});
