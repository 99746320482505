const optionsCountry = [
  // { value: "101", label: "India" },
  {value: '191', label: 'Saudi Arabia'},
  {value: '229', label: 'United Arab Emirates'},
];

const companyTypes = [
  {title: 'Free zone'},
  {title: 'Ownership'},
  {title: 'Limited Liability'},
  {title: 'Private Limited'},
  {title: 'Other'},
];

const countryCode = [
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'AE',
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN',
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA',
  },
];

const listedByOption = [
  {value: 'AGENT', label: 'Agent'},
  {value: 'OWNER', label: 'Owner'},
  {value: 'AQARCHAIN', label: 'AqarChain'},
];

const currencyOption = [
  {value: 'USD', label: 'USD'},
  {value: 'AED', label: 'AED'},
  {value: 'SAR', label: 'SAR'},
];
const viewTypeOption = [
  {value: 'floorPlans', label: 'Floor Plans'},
  {value: 'video', label: 'Video Tours'},
  {value: '_360View', label: '360 Tours'},
];

export {
  optionsCountry,
  listedByOption,
  currencyOption,
  viewTypeOption,
  countryCode,
  companyTypes,
};
