import React from 'react';
import {defaultOptions} from '../../../config';

export default ({data}) => {
  return (
    <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow bg-black">
      <h2 className="font-weight-bold mb-4">Amenities</h2>
      <div
        id="property-detail-amenities"
        className="text-center d-block d-lg-flex justify-content-around">
        {data.hasOwnProperty('amenities')
          ? data.amenities.map((item, index) => {
              return (
                <div key={index} className="property-detail-amenity">
                  <img
                    src={`${defaultOptions.baseUrl}api/${item.image}`}
                    alt={item.feature}
                  />
                  <p className="mb-3 mt-3 mb-lg-0 font-weight-bold">
                    {item.feature}
                  </p>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};
