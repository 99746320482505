import React, {useRef} from 'react';
import {Fragment} from 'react';
import {connect} from 'react-redux';
import {
  getWebAccessToken,
  getWebAccessTokenIndividual,
  saveaccessCode,
  varifyWebAccessToken,
  regenerateWebAccessToken,
} from '../../../redux/actions';
import isLoadingHOC from '../../common/IsLoadingHOC';
import {toast} from 'react-toastify';
import Popup from 'reactjs-popup';
import Modal from '../../Modal';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      company_name: '',
      venture: '',
      company_site: '',
      role: '',
      teamsize: '0-20',
      accessCode: '',
      tabCheck: true,
      hide: false,
      flag: false,
      activeTab: true,
    };
    //creates a reference for your element to use
    this.EnterAccressTokenSection = React.createRef();
  }

  handleOnClickAccessCode = event => {
    setTimeout(() => {
      if (this.EnterAccressTokenSection.current) {
        this.EnterAccressTokenSection.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }, 1000);
  };

  saveAccessCode = () => {
    //alert("save access");
    const {setLoading} = this.props;
    setLoading(true);
    this.props
      .varifyWebAccessToken({token: this.state.accessCode})
      .then(response => {
        setLoading(false);
        console.log(response);
        toast.success(response.message);
        setTimeout(function() {
          window.location.reload();
        }, 1000);
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.log(error);
      });
  };
  onSubmit = () => {
    this.setState({
      flag: true,
    });
    const {setLoading} = this.props;
    setLoading(true);
    const {
      name,
      email,
      phone,
      company_name,
      venture,
      company_site,
      role,
      teamsize,
      country,
    } = this.state;
    const data = {
      name,
      email,
      phone,
      company_name,
      venture,
      company_site,
      role,
      teamsize,
      country,
    };
    this.props
      .getWebAccessToken(data)
      .then(response => {
        console.log(response);
        this.setState({
          hide: true,
        });
        toast.success(response.message);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        toast.success(error);
      });
  };

  onSubmitIndividual = () => {
    this.setState({
      flag: true,
    });
    const {setLoading} = this.props;
    setLoading(true);
    const {name, email, phone, country} = this.state;
    const data = {
      name,
      email,
      phone,
      country,
    };
    this.props
      .getWebAccessTokenIndividual(data)
      .then(response => {
        console.log(response);
        this.setState({
          hide: true,
        });
        toast.success(response.message);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        toast.success(error);
      });
  };

  modalClose() {
    this.setState({
      hide: false,
      flag: false,
    });
  }
  regenerateAccessCode = close => {
    const {setLoading} = this.props;
    setLoading(true);
    this.props
      .regenerateWebAccessToken({email: this.state.email})
      .then(response => {
        console.log(response);
        close();
        toast.success(response.message);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        //close();
        console.log(error);
      });
  };
  forgotComponent = close => {
    return (
      <>
        <div className="col-lg-6 col-12 my-lg-0 my-3">
          <label htmlFor="email">Your Email Address</label>
          <input
            onChange={e => this.setState({email: e.target.value})}
            type="text"
            className="form-control"
            id="email"
            placeholder="Enter your Email"
          />
        </div>

        <div className="col-lg-12">
          <button
            onClick={() => this.regenerateAccessCode(close)}
            className="btn btn-gradient-secondary btn-custom-width has-border-radius">
            Submit
          </button>
        </div>
      </>
    );
  };
  render() {
    console.log('this.propsthis.props', this.props.location.pathname);
    return (
      <Fragment>
        <header className="landing-page-header">
          <div className="container-lg">
            <nav className="navbar navbar-expand-lg navbar-light">
              <a className="navbar-brand mx-4" href="/">
                <img
                  src="./images/logo.svg"
                  style={{height: '90px'}}
                  title="Aqarchain"
                  alt="Aqarchain"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-xl-end justify-content-end"
                id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#landing-page-section_3">
                      Why Us?
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#landing-page-section_4">
                      Benefits
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#landing-page-section_5">
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#landing-page-section_6">
                      FAQ
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
        <section id="landing-page-section_1">
          <div id="landing-page-section_1-container" className="container-lg">
            <div className="row no-gutters">
              <div className="col-lg-12 col-12 text-center">
                <div className="mobile-screen">
                  <img
                    alt=""
                    className="mobile-bg"
                    src="./images/landing-page-section_1-mobile-bg.png"
                  />
                  <div className="description text-center">
                    <img
                      className="my-6"
                      src="./images/logo.svg"
                      style={{height: '110px'}}
                      title="Aqarchain"
                      alt="Aqarchain"
                    />
                    <p className="font-weight-medium">New way to explore</p>
                    <h2 className="font-weight-bold">Real Estate</h2>
                    <button
                      onClick={() => this.handleOnClickAccessCode()}
                      className="btn btn-gradient-secondary has-border-radius cursor-pointer my-5">
                      Get Access Code
                    </button>
                  </div>
                </div>
                <div className="inner-black-background">
                  <img
                    alt=""
                    className="img-fluid"
                    src="./images/inner-gradient.svg"
                  />
                </div>
                <div className="outer-black-background">
                  <img
                    alt=""
                    className="img-fluid"
                    src="./images/outer-gradient.svg"
                  />
                </div>
                <img
                  alt=""
                  className="rotating-icon-list"
                  src="./images/landing-page-section-1_rotating-icons.svg"
                />
              </div>
            </div>
          </div>
        </section>
        <section
          id="landing-page-section_2"
          ref={this.EnterAccressTokenSection}>
          <div id="landing-page-section_2-container" className="container-lg">
            <div className="bg-white mx-lg-8 mx-3 has-box-shadow">
              <div className="row no-gutters">
                <div className="col-lg-6 col-12">
                  <img
                    className="img-fluid"
                    src="./images/landing-page-section-2_submit-access-code-artwork.png"
                  />
                </div>
                <div className="col-lg-6 col-12">
                  <div className="text-md-left text-center p-5 mt-7">
                    <h1 className="font-weight-bold mb-4">Access Code</h1>
                    <p className="mb-4 text-justify">
                      An Access Code is required to view the Aqar chain platform
                      since it is in Beta stage. The access code can be made
                      available by submitting the details and showing your
                      interest in Aqarchain
                    </p>
                    <input
                      onChange={e =>
                        this.setState({accessCode: e.target.value})
                      }
                      className="form-control primary-input"
                      type="text"
                      placeholder="Enter Code Here"
                    />
                    <button
                      onClick={() => this.saveAccessCode()}
                      className="btn btn-gradient-secondary my-4 has-border-radius btn-custom-width">
                      Submit
                    </button>
                    <div
                      onClick={this.handleOnClickAccessCode}
                      className="text-dark cursor-pointer mt-5">
                      <p>Haven't registered yet?</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="landing-page-section_3">
          <div id="landing-page-section_3-container" className="container-lg">
            <div className="mx-lg-8 mx-3">
              <div className="row no-gutters">
                <div className="col-lg-6 col-12 img-fluid-col">
                  <img
                    className="img-fluid"
                    src="./images/landing-page-section-3_about-us-artwork.png"
                  />
                </div>
                <div className="col-lg-6 col-12">
                  <div className="text-md-left text-center p-3">
                    <h1 className="font-weight-bold mt-lg-7 mb-4 whyus_header">
                      Why Us
                    </h1>
                    <ul className="about-us-list">
                      <li>
                        <h4>
                          <b>
                            Data Governance: Accessibility Improvement to
                            Property Data
                          </b>
                        </h4>
                        <p className="text-justify">
                          A platform built on a blockchain platform will allow
                          users to have more control over their data. An
                          intermediary won’t be able to manipulate the data.
                          This will lower down the cost & enhance the
                          transparency
                        </p>
                      </li>
                      <li>
                        <h4>
                          <b>Market Intelligence with Reports</b>
                        </h4>
                        <p className="text-justify">
                          Our home grown market intelligence reports add value
                          to the property listing, providing with advanced
                          analytics such as property score, estimated sale
                          value, rental value in short term and long term.
                        </p>
                      </li>
                      <li>
                        <h4>
                          <b>Background Checks</b>
                        </h4>
                        <p className="text-justify">
                          It consists of multiple verifying options present on
                          the network that can quickly validate the information.
                          The process will significantly fasten the process.
                        </p>
                      </li>
                      <li>
                        <h4>
                          <b>Easy Payment Options</b>
                        </h4>
                        <p className="text-justify">
                          As it contains smart contracts (tezos) which automate
                          all the processes and allow multiple stakeholders to
                          coordinate among each other, to pay the rent and
                          distribute lease payouts for various properties. These
                          transactions can be traced back for future reference.
                        </p>
                      </li>
                      <li>
                        <h4>
                          <b>Free Listings</b>
                        </h4>
                        <p className="text-justify">
                          Listing Property on Aqarchain is completely free of
                          any charge or commission for individuals and agents or
                          brokers. The Listing will remain free for life.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="landing-page-section_4">
          <div id="landing-page-section_4-container" className="container-lg">
            <div className="mx-lg-8 mx-3">
              <h3 className="font-weight-medium text-center">
                Benefits of Property Listing
              </h3>
              <h1 className="font-weight-bold text-center">On Aqarchain</h1>
              <div className="row no-gutters my-5">
                <div className="col-lg-4 col-12">
                  <div className="d-flex align-items-center my-3">
                    <div className="benefit-description">
                      <h3 className="font-weight-bold">Live Viewings</h3>
                      <p className="text-justify">
                        Attend viewings in a live stream whenever you want. can
                        ask questions in real-time and get an immediate reply.{' '}
                      </p>
                    </div>
                    <div className="benefit-image">
                      <img
                        className="img-fluid"
                        src="./images/feature-Live.png"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-3">
                    <div className="benefit-description">
                      <h3 className="font-weight-bold">Video Tours</h3>
                      <p className="text-justify">
                        Watch pre-recorded videos of the property.
                      </p>
                    </div>
                    <div className="benefit-image">
                      <img
                        className="img-fluid"
                        src="./images/feature-Video.png"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-3">
                    <div className="benefit-description">
                      <h3 className="font-weight-bold">360 Tours</h3>
                      <p className="text-justify">
                        Discover a panoramic view of the property you are
                        looking to buy.
                      </p>
                    </div>
                    <div className="benefit-image">
                      <img
                        className="img-fluid"
                        src="./images/feature-360.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="text-center">
                    <img
                      className="benefits-artwork img-fluid"
                      src="./images/landing-page-section_4-mobile-screen.png"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="d-flex align-items-center my-3">
                    <div className="benefit-description">
                      <h3 className="font-weight-bold">Google Map</h3>
                      <p className="text-justify">
                        To help customers to find the exact location of the
                        properties.
                      </p>
                    </div>
                    <div className="benefit-image">
                      <img
                        className="img-fluid"
                        src="./images/feature-GoogleMap.png"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-3">
                    <div className="benefit-description">
                      <h3 className="font-weight-bold">24*7 Support</h3>
                      <p className="text-justify">
                        Customers can enquire about the property anytime they
                        want.{' '}
                      </p>
                    </div>
                    <div className="benefit-image">
                      <img
                        className="img-fluid"
                        src="./images/feature-24x7.png"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-3">
                    <div className="benefit-description">
                      <h3 className="font-weight-bold">Payment Support</h3>
                      <p className="text-justify">
                        Secured payment gateways for hassle-free and safe
                        payments.{' '}
                      </p>
                    </div>
                    <div className="benefit-image">
                      <img
                        className="img-fluid"
                        src="./images/feature-Payment.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="landing-page-section_5">
          <div id="landing-page-section_5-container" className="container-lg">
            <h1 className="font-weight-bold text-white text-center mb-7">
              Get Access Code
            </h1>
            <div className="row no-gutters">
              <div className="col-lg-7 offset-lg-5 col-12">
                <div className="has-box-shadow p-lg-5 p-4 bg-white mx-lg-5 mx-3">
                  <div id="access-code-form-tab-links">
                    <ul
                      className="nav nav-tabs nav-tabs nav-justified md-tabs access-code-form-nav"
                      id="access-code-form-tabs">
                      <li className="nav-item mx-md-1 m-2">
                        <a
                          className={`nav-link text-nowrap ${
                            this.state.activeTab ? 'active' : ''
                          }`}
                          href="#a"
                          id="access-code-form-tab-agent-tab"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({activeTab: !this.state.activeTab});
                          }}>
                          Agent
                        </a>
                      </li>
                      <li className="nav-item mx-md-1 m-2">
                        <a
                          className={`nav-link text-nowrap ${
                            this.state.activeTab ? '' : 'active'
                          }`}
                          href="#a"
                          id="access-code-form-tab-individual-tab"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({activeTab: !this.state.activeTab});
                          }}>
                          Individual
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content access-code-form-nav-content"
                    id="access-code-form-nav-content">
                    {this.state.activeTab ? (
                      <div
                        className="tab-pane fade show active"
                        id="access-code-form-tab-agent"
                        role="tabpanel"
                        aria-labelledby="access-code-form-tab-agent-tab">
                        <div className="my-5">
                          <div className="row my-5">
                            <div className="col-lg-6 col-12 my-lg-0 my-3">
                              <label htmlFor="name">Your Name</label>
                              <input
                                onChange={e =>
                                  this.setState({name: e.target.value})
                                }
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter your Name"
                              />
                            </div>
                            <div className="col-lg-6 col-12 my-lg-0 my-3">
                              <label htmlFor="email">Your Email Address</label>
                              <input
                                onChange={e =>
                                  this.setState({email: e.target.value})
                                }
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Enter your Email"
                              />
                            </div>
                          </div>
                          <div className="row my-5">
                            <div className="col-lg-6 col-12 my-lg-0 my-3">
                              <label htmlFor="phone">Your Phone</label>
                              <input
                                onChange={e =>
                                  this.setState({phone: e.target.value})
                                }
                                type="number"
                                className="form-control"
                                id="phone"
                                placeholder="Enter your Phone"
                              />
                            </div>
                            <div className="col-lg-6 col-12 my-lg-0 my-3">
                              <label htmlFor="venture">
                                Where is your venture incorporated?
                              </label>
                              <input
                                onChange={e =>
                                  this.setState({venture: e.target.value})
                                }
                                type="text"
                                className="form-control"
                                id="venture"
                                placeholder="Enter Venture Address"
                              />
                            </div>
                          </div>
                          <div className="row my-5">
                            <div className="col-lg-6 col-12 my-lg-0 my-3">
                              <label htmlFor="company-name">
                                Your Company's Name
                              </label>
                              <input
                                onChange={e =>
                                  this.setState({company_name: e.target.value})
                                }
                                type="text"
                                className="form-control"
                                id="company-name"
                                placeholder="Enter your Company Name"
                              />
                            </div>
                            <div className="col-lg-6 col-12 my-lg-0 my-3">
                              <label htmlFor="company-website">
                                Your Company's Website
                              </label>
                              <input
                                onChange={e =>
                                  this.setState({company_site: e.target.value})
                                }
                                type="text"
                                className="form-control"
                                id="company-website"
                                placeholder="Enter your Website"
                              />
                            </div>
                          </div>
                          <div className="row my-5">
                            <div className="col-lg-6 col-12 my-lg-0 my-3">
                              <label htmlFor="role">
                                Your Role in the Company
                              </label>
                              <input
                                onChange={e =>
                                  this.setState({role: e.target.value})
                                }
                                type="text"
                                className="form-control"
                                id="role"
                                placeholder="Enter your Role"
                              />
                            </div>
                            <div className="col-lg-6 col-12 my-lg-0 my-3">
                              <label htmlFor="team-size">
                                How big is your Team?
                              </label>
                              <select
                                onChange={e =>
                                  this.setState({teamsize: e.target.value})
                                }
                                className="form-control"
                                id="team-size"
                                placeholder="Select Team Size">
                                <option value="0-20">0-20</option>
                                <option value="20-50">20-50</option>
                                <option value="50-100">50-100</option>
                                <option value="100+">100+</option>
                              </select>
                            </div>
                            <div className="col-lg-12 my-5">
                              <div className="form-check">
                                <input
                                  onChange={e =>
                                    this.setState({tabCheck: true})
                                  }
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="subscribe-aqarchain"
                                />
                                <label
                                  className="form-check-label font-weight-bold text-dark"
                                  htmlFor="subscribe-aqarchain">
                                  Let Aqarchain keep me up to date
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button
                                onClick={() => {
                                  this.onSubmit();
                                  this.handleOnClickAccessCode();
                                }}
                                className="btn btn-gradient-secondary btn-custom-width has-border-radius">
                                Submit
                              </button>
                              {this.state.flag ? (
                                <Modal
                                  show={this.state.hide}
                                  handleClose={e => this.modalClose(e)}>
                                  <div className="form-group">
                                    <p className="text-justify">
                                      Thank you for showing your interest in
                                      Aqarchain. Our team will update you soon
                                      with an access code to view the Aqarchain
                                      Platform. In the mean while stay in touch
                                      by joining our telegram group and other
                                      social media channels for the latest
                                      updates and information.
                                    </p>
                                  </div>
                                </Modal>
                              ) : null}
                              {/* <Popup 
															trigger={<div id="property-detail-meta-360_tour" 
																className="text-dark cursor-pointer mt-5">
																<p>success message</p></div>} modal>
															{ close => this.successMessage(close) }
														</Popup>	 */}
                            </div>
                            <br />
                            <div className="col-lg-12">
                              <Popup
                                trigger={
                                  <div
                                    id="property-detail-meta-360_tour"
                                    className="text-dark cursor-pointer mt-5">
                                    <p>
                                      Already register, Regenerate access code
                                      again?
                                    </p>
                                  </div>
                                }
                                modal>
                                {close => this.forgotComponent(close)}
                              </Popup>
                              {/* <p className=""></p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="tab-pane fade show active"
                        id="access-code-form-tab-individual"
                        role="tabpanel"
                        aria-labelledby="access-code-form-tab-individual-tab">
                        <div className="my-5">
                          <div className="row my-5">
                            <div className="col-lg-6 col-12 my-lg-0 my-5">
                              <label htmlFor="name">Your Name</label>
                              <input
                                onChange={e =>
                                  this.setState({name: e.target.value})
                                }
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter Your Name"
                              />
                            </div>
                            <div className="col-lg-6 col-12 my-lg-0 my-5">
                              <label htmlFor="email">Your Email Address</label>
                              <input
                                onChange={e =>
                                  this.setState({email: e.target.value})
                                }
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Enter Your Email Address"
                              />
                            </div>
                          </div>

                          <div className="row my-5">
                            <div className="col-lg-6 col-12 my-lg-0 my-5">
                              <label htmlFor="phone">Your Contact Number</label>
                              <input
                                onChange={e =>
                                  this.setState({phone: e.target.value})
                                }
                                type="number"
                                className="form-control"
                                id="phone"
                                placeholder="Enter Your Contact Number"
                              />
                            </div>
                            <div className="col-lg-6 col-12 my-lg-0 my-5">
                              <label htmlFor="venture">Select Country</label>
                              <select
                                onChange={e =>
                                  this.setState({country: e.target.value})
                                }
                                className="form-control"
                                id="role"
                                placeholder="Select Country">
                                <option value="AE">United Arab Emirates</option>
                                <option value="SA">Saudi Arabia</option>
                              </select>
                            </div>
                            <div className="col-lg-12 my-5">
                              <div className="form-check">
                                <input
                                  onChange={e =>
                                    this.setState({tabCheck: true})
                                  }
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="subscribe-aqarchainOne"
                                />
                                <label
                                  className="form-check-label font-weight-bold text-dark"
                                  htmlFor="subscribe-aqarchainOne">
                                  Let Aqarchain keep me up to date
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button
                                onClick={() => {
                                  this.onSubmitIndividual();
                                  this.handleOnClickAccessCode();
                                }}
                                // onClick={() =>
                                //   this.setState({flag: true, hide: true})
                                // }
                                className="btn btn-gradient-secondary btn-custom-width has-border-radius">
                                Submit
                              </button>
                              {/* {this.state.flag ? (
                                <Modal
                                  show={this.state.hide}
                                  handleClose={e => this.modalClose(e)}>
                                  <div className="form-group">
                                    <p className="text-justify">
                                      Thank you for showing your interest in
                                      Aqarchain. Our team will update you soon
                                      with an access code to view the Aqarchain
                                      Platform. In the mean while stay in touch
                                      by joining our telegram group and other
                                      social media channels for the latest
                                      updates and information.
                                    </p>
                                  </div>
                                </Modal>
                              ) : null} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="landing-page-section_6">
          <div id="landing-page-section_6-container" className="container-lg">
            <h1 className="font-weight-bold text-center">
              Frequently Asked Questions
            </h1>
            <div className="row no-gutters">
              <div className="col-lg-12 col-12">
                <div
                  className="accordion mx-lg-9 my-lg-5 mx-3"
                  id="accordionExample">
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingOne"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne">
                      <p className="mb-0">What is Aqarchain?</p>
                      <i className="fa fa-plus mr-4"></i>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample">
                      <div className="card-body">
                        Aqarchain is a platform that allows listing and
                        searching of properties. Aqarchain as a platform is
                        completely free to list properties and does not charge
                        any brokerage on sale or lease of properties through the
                        platform
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingTwo"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo">
                      <p className="mb-0">Who can list a property?</p>
                      <i className="fa fa-plus mr-4"></i>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample">
                      <div className="card-body">
                        Any individual who owns a property or an agent from a
                        real estate brokerage firm can list property on
                        Aqarchain.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingThree"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                      <p className="mb-0">
                        What type of properties can be listed on Aqarchain?
                      </p>
                      <i className="fa fa-plus mr-4"></i>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample">
                      <div className="card-body">
                        All types of properties, Residential, Commercial, or
                        Retail can be listed on Aqarchain.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingFour"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                      <p className="mb-0">
                        How is Aqarchain different from the other property
                        portals?
                      </p>
                      <i className="fa fa-plus mr-4"></i>
                    </div>
                    <div
                      id="collapseFour"
                      className="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample">
                      <div className="card-body">
                        Aqarchain is completely free to list properties for
                        everyone, agents or individuals. Aqarchain is an
                        investment platform and will have accredited investors
                        globally looking to invest in real estate assets.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingFive"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                      <p className="mb-0">
                        How is the process of listing a property?
                      </p>
                      <i className="fa fa-plus mr-4"></i>
                    </div>
                    <div
                      id="collapseFive"
                      className="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample">
                      <div className="card-body">
                        Aqarchain has its own functional platform where as a
                        property owner you can add properties through a simple
                        form, get a system to manage queries, and also manage
                        multiple listings.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingSeven"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                      <p className="mb-0">
                        {' '}
                        Can I list more than one property?
                      </p>
                      <i className="fa fa-plus mr-4"></i>
                    </div>
                    <div
                      id="collapseSix"
                      className="collapse"
                      aria-labelledby="headingSeven"
                      data-parent="#accordionExample">
                      <div className="card-body">
                        Yes Aqarchain supports multiple listing, and for real
                        estate agents Aqarchain provides a special platform to
                        manage multiple users, manage leads and enquiries, all
                        this completely free at zero costs.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingSeven"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseSix1"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                      <p className="mb-0"> Is there any hidden fees?</p>
                      <i className="fa fa-plus mr-4"></i>
                    </div>
                    <div
                      id="collapseSix1"
                      className="collapse"
                      aria-labelledby="headingSeven"
                      data-parent="#accordionExample">
                      <div className="card-body">
                        Aqarchain is completely free and will remain free to
                        list properties. Aqarchain connects buyers and sellers
                        on a single platform.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingSix"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                      <p className="mb-0">How can I promote my listing?</p>
                      <i className="fa fa-plus mr-4"></i>
                    </div>
                    <div
                      id="collapseSeven"
                      className="collapse"
                      aria-labelledby="headingSix"
                      data-parent="#accordionExample">
                      <div className="card-body">
                        Currently Aqarchain does not charge for premium
                        listings, however only properties that are listed
                        through aqar verified will have a preference tick mark.
                        This does not ensure listings on top. On Aqarchain
                        listing is purely on keyword relevance. Aqarcain does
                        allow social sharing through the platform with all major
                        social networking platforms.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer landing-page-footer text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 col-md-12 col-12">
                <img
                  className="nav-brand"
                  src="./images/logo_white.svg"
                  alt="website"
                  width="170px"
                  height="170px"
                />
              </div>
              <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center">
                <div className="d-inline-block text-lg-left mt-4">
                  <p className="website-description">
                    ©️ Smart Chain Information Technology Consultancy Ltd. 2020
                    - 2021
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="d-inline-block mt-3 text-lg-left p-4">
                  <h3 className="text-white mb-4">FOLLOW US</h3>
                  <ul className="nav social-nav flex-row">
                    <li className="nav-item">
                      <a
                        className="footer-link cursor-pointer"
                        href={'https://www.linkedin.com/company/18961648/'}
                        target="_blank">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="footer-link cursor-pointer"
                        href={'https://twitter.com/aqar_chain'}
                        target="_blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="footer-link cursor-pointer"
                        href={
                          'https://www.facebook.com/Aqar-Chain-105367495055665'
                        }
                        target="_blank">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="footer-link cursor-pointer"
                        href={'https://t.me/aqrxtoken'}
                        target="_blank">
                        <i class="fab fa-telegram-plane"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="footer-link cursor-pointer"
                        href={'https://www.instagram.com/aqarchain/?hl=en'}
                        target="_blank">
                        <i class="fab fa-instagram"></i>{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="d-block mt-3 text-lg-left p-4">
                  <h3 className="text-white mb-3">NEWSLETTER</h3>
                  <div className="input-group newsletter-email">
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      placeholder="Enter your Email"
                    />
                    <div className="input-group-append">
                      <i className="far fa-envelope"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.props.location.pathname === '/' && (
            <div className="container footer_bottom">
              <div className="row">
                <div class="col-lg-2 text-center"></div>
                <div className="col-md-8">
                  <p class="mb-3 text-left">Disclosure</p>
                  <p class="text-justify">
                    Aqarchain is a 100% subsidiary of Smart Chain Information
                    Technology Consultancy Ltd. We have been exploring the
                    blockchain space in the middle east and it has turned out to
                    be one of the first adopters in the world. The UAE has been
                    working towards a strategic future vision in terms of
                    adopting technology and creating a regulatory framework.
                    Thus, we have created Aqarchain, a real estate asset
                    tokenization Platform, using Blockchain technology. An asset
                    tokenization platform that enables fractional Ownership.
                  </p>
                  <p className="text-justify">
                    Aqarchain Ltd. (proposed entity name to be incorporated in
                    DIFC) is in its inception stage and is inviting early stage
                    investors to be a part of the community through its Native
                    token sale (AQR). Aqarchain is applying for a crowdfunding
                    license and exploring options to be regulated at DIFC.
                  </p>
                </div>
                <div class="col-lg-2 text-center"></div>
              </div>
            </div>
          )}
        </footer>
      </Fragment>
    );
  }
}

export default connect(null, {
  getWebAccessToken,
  getWebAccessTokenIndividual,
  saveaccessCode,
  varifyWebAccessToken,
  regenerateWebAccessToken,
})(isLoadingHOC(Landing, 'Loading ....'));
