import React from 'react';
import {useField} from 'formik';
const Radio = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <>
      <input
        className="form-check-input"
        type="radio"
        name="interested-option"
        {...field}
        {...props}
      />
      <label className="form-check-label" htmlFor={props.id || props.name}>
        {label}
      </label>
    </>
  );
};
export default Radio;
