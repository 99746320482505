import React from 'react';
import {connect} from 'react-redux';
import {
  searchProperty,
  likeProperty,
  addToWishList,
  removeFromWhisList,
  removeLikeProperty,
  getUserSavedProperties,
} from '../../../redux/actions';
import GoogleMapReact from 'google-map-react';
import {defaultOptions} from '../../../config';
import PaginationCustom from '../../common/PaginationCustom';
import {Link} from 'react-router-dom';
import Image from '../../common/ProjectComponent/Image';
import AqarVarified from '../../common/AqarVarified';
import Price from '../../common/ProjectComponent/Price';
import Rating from '../../pages/SearchView/Rating.Component';
import Popup from 'reactjs-popup';
import {toast} from 'react-toastify';
import {PropertyCreateDate} from '../../common/PropertyCreateDate';
import PropertyLike from '../PropertyLike';
import PropertyWishList from '../PropertyWishList';

const CardView = props => {
  const {
    propertyData,
    token,
    activePage,
    totalItemsCount,
    itemCountPerpage,
    handleShowNumberOfRecords,
    handlePagination,
  } = props;
  return (
    <div id="search-results-list">
      {propertyData.length > 0
        ? propertyData.map((item, index) => {
            return (
              <div className="list-property" key={index}>
                <div className="row">
                  <div className="col-xl-3 text-center">
                    <div className="list-property-image mb-1 mb-xl-0">
                      <a href={`property-detail/${item._id}`}>
                        <Image url={item.cover} className="w-100" />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-9">
                    <div className="list-property-content">
                      <div className="list-property-content-header">
                        <p className="mb-0 d-block d-lg-flex justify-content-center justify-content-lg-start align-items-center text-center">
                          <span className="d-block d-lg-flex mb-2 mb-lg-0 mr-lg-5 ml-lg-auto">
                            Property ID:{' '}
                            <span className="font-weight-bold ml-2">
                              {item.id}
                            </span>
                          </span>
                          <span className="d-block d-lg-flex mb-2 mb-lg-0 mr-lg-auto font-weight-bold">
                            {item.title}
                          </span>
                          {item?.aqarVerified && <AqarVarified />}
                        </p>
                      </div>
                      <div className="list-property-content-company">
                        <div className="mb-0 d-block d-lg-flex justify-content-center align-items-center justify-content-lg-start align-items-lg-end text-center">
                          <div className="property-agent d-block d-lg-flex flex-column align-items-center mb-2 mb-lg-0 mr-lg-5 text-center">
                            <div className="agent-image-box">
                              <img
                                className="property-agent-image"
                                src={`${
                                  item.listedBy === 'COMPANY' ||
                                  item.listedBy === 'AGENT'
                                    ? `${
                                        !item.belongTo[0]
                                          ? 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
                                          : `${defaultOptions.baseUrl}/api${item.belongTo[0].profile}`
                                      }`
                                    : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
                                }`}
                                alt=""
                              />
                            </div>
                            <span className="property-agent-name d-block d-lg-flex">
                              {!item.belongTo[0] ? '' : item.belongTo[0].role} :{' '}
                              <span className="font-weight-bold ml-2 text-capitalize">
                                {!item.belongTo[0]
                                  ? 'NA'
                                  : item.belongTo[0].first_name}{' '}
                                {item.belongTo[0] && item.belongTo[0].last_name}
                              </span>
                            </span>
                          </div>
                          <span className="property-registration d-block d-lg-flex mb-2 mb-lg-0 mr-lg-auto">
                            Rera No.:{' '}
                            <span className="font-weight-bold ml-2">
                              {`#${
                                item.listedBy === 'COMPANY' && item?.belongTo
                                  ? `${
                                      !item.belongTo[0]
                                        ? 'NA'
                                        : item.belongTo[0].reraLicenseNumber
                                    }`
                                  : ''
                              }${
                                item.listedBy === 'AGENT' && item?.company
                                  ? `${
                                      !item.company[0]
                                        ? 'NA'
                                        : item.company[0].reraLicenseNumber
                                    }`
                                  : ''
                              }`}
                            </span>
                          </span>
                          <span className="property-registration d-block d-lg-flex mb-2 mb-lg-0 mr-lg-auto">
                            Upload Date:{' '}
                            <span className="font-weight-bold ml-2">
                              <PropertyCreateDate
                                propertyDate={item.createdAt}
                              />
                            </span>
                          </span>
                          <div className="property-company d-flex justify-content-center justify-content-lg-end align-items-center ml-lg-5 text-left">
                            <span className="property-company-name">
                              Company: <br />
                              <span className="font-weight-bold text-capitalize">
                                {item.listedBy === 'COMPANY' &&
                                  item?.belongTo &&
                                  item.belongTo[0]?.companyName}
                                {item.listedBy === 'AGENT' &&
                                  item?.company &&
                                  item.company[0]?.companyName}
                              </span>
                            </span>
                            <img
                              className="property-company-image"
                              src={`${defaultOptions.baseUrl}${
                                item.listedBy === 'COMPANY'
                                  ? item.belongTo[0]?.profile
                                  : ''
                              }${
                                item.listedBy === 'AGENT'
                                  ? item.company[0]?.profile
                                  : ''
                              }`}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="list-property-content-details">
                        <div className="d-block d-lg-flex justify-content-center justify-content-lg-between align-items-center mb-4 text-center">
                          <div className="property-meta d-block d-lg-flex mr-5 mb-3 mb-lg-0">
                            <div className="property-price pb-2 mb-3 pb-lg-0 mb-lg-0 pr-lg-3 mr-lg-3">
                              <p className="mb-2 mb-lg-0">
                                Price <br />
                                <span className="font-weight-bold">
                                  <Price
                                    price={item.price}
                                    currency={item.currency}
                                  />
                                </span>
                              </p>
                            </div>

                            <div className="property-location pb-2 mb-3 pb-lg-0 mb-lg-0 pr-lg-3 mr-lg-3">
                              <p className="mb-2 mb-lg-0">
                                Location <br />
                                <span className="font-weight-bold">
                                  {item.address} {item.city}, {item.state}{' '}
                                  {item.country}{' '}
                                </span>
                              </p>
                            </div>
                            <div className="property-type">
                              <p className="mb-0">
                                Type <br />
                                <span className="font-weight-bold">
                                  {item?.property_type_data &&
                                    item.property_type_data[0].type_title}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="property-share d-flex justify-content-center">
                            <Popup
                              trigger={
                                <div className="property-rating cursor-pointer pr-2 mr-2 pr-lg-3 mr-lg-3">
                                  <img
                                    src={require(`../../../assets/images/icon-star${
                                      item.ratings >= 1 ? '-active' : ''
                                    }.svg`)}
                                    alt=""
                                  />
                                  <img
                                    src={require(`../../../assets/images/icon-star${
                                      item.ratings >= 2 ? '-active' : ''
                                    }.svg`)}
                                    alt=""
                                  />
                                  <img
                                    src={require(`../../../assets/images/icon-star${
                                      item.ratings >= 3 ? '-active' : ''
                                    }.svg`)}
                                    alt=""
                                  />
                                  <img
                                    src={require(`../../../assets/images/icon-star${
                                      item.ratings >= 4 ? '-active' : ''
                                    }.svg`)}
                                    alt=""
                                  />
                                  <img
                                    src={require(`../../../assets/images/icon-star${
                                      item.ratings >= 5 ? '-active' : ''
                                    }.svg`)}
                                    alt=""
                                  />
                                </div>
                              }
                              modal
                              nested>
                              {close =>
                                token ? (
                                  <Rating item={item} close={close} />
                                ) : (
                                  <div>Login first for rating</div>
                                )
                              }
                            </Popup>
                            <PropertyLike item={item} />
                            <PropertyWishList item={item} />
                          </div>
                        </div>

                        <div
                          className="modal fade"
                          id={item._id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel">
                                  {item.title}
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div
                                className="modal-body"
                                style={{
                                  widht: '400px',
                                  height: '400px',
                                }}>
                                <GoogleMapReact
                                  bootstrapURLKeys={{
                                    key: defaultOptions.googleGeoLocationKey,
                                  }}
                                  defaultZoom={12}
                                  defaultCenter={{
                                    lat: item.location.coordinates[0],
                                    lng: item.location.coordinates[1],
                                  }}
                                  yesIWantToUseGoogleMapApiInternals
                                  onGoogleApiLoaded={({map, maps}) =>
                                    new maps.Marker({
                                      position: {
                                        lat: item.location.coordinates[0],
                                        lng: item.location.coordinates[1],
                                      },
                                      map,
                                      title: item.title,
                                    })
                                  }></GoogleMapReact>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-dismiss="modal">
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-block d-lg-flex justify-content-center justify-content-lg-between">
                          <p className="mb-3 mb-lg-0 mr-0 mr-lg-5 text-center text-lg-left">
                            {item.discription}
                          </p>
                          <div className="d-block d-lg-flex justify-content-center justify-content-lg-end">
                            <button
                              type="button"
                              data-toggle="modal"
                              data-target={`#${item._id}`}
                              className="btn btn-gradient-secondary btn-rounded w-100 w-lg-auto mb-2 mb-lg-0 mr-lg-3">
                              Location
                            </button>
                            <Link
                              className="btn btn-gradient btn-rounded w-100 w-lg-auto"
                              to={{
                                pathname: `/property-detail/${item._id}`,
                                query: item,
                              }}>
                              View
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
      {propertyData.length !== 0 && (
        <PaginationCustom
          handleShowNumberOfRecords={handleShowNumberOfRecords}
          handlePagination={handlePagination}
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemCountPerpage={itemCountPerpage}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {products, viewType, token} = state.app;
  return {
    products: products,
    viewType: viewType,
    token,
  };
};

export default connect(mapStateToProps, {likeProperty, addToWishList})(
  CardView,
);
