import React, {useState, useEffect} from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
const Investments = () => {
  return (
    <>
      <section key="11212" className="investment--banner">
        <div id="landing-section_1-banner" className="container-lg">
          <div className="row no-gutters">
            <div className="col-lg-12 text-center text-lg-left">
              <div className="banner--heading">
                <h1 class="mb-3">
                  Unlock the Door to
                  <span class="font-weight-bold">Seamless Growth &amp; </span>
                  <span class="font-weight-bold">Endless Opportunities</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Section1 />
      <section id="landing-section_5">
        <div
          id="landing-section_5-fractional_ownership"
          className="container-lg">
          <div className="row no-gutters">
            <div className="col-lg-6 text-center">
              <img
                alt=""
                className="img-fluid w-90"
                style={{padding: 0}}
                src={require('../../../assets/images/investor-banner.png')}
              />
            </div>
            <div className="col-lg-6 text-center text-md-left">
              <div className="p-4 p-md-6 pr-md-9 mx-3 has-box-shadow bg-dots-variant-1 bg-position-top-right-offset bg-repeat-no-repeat">
                <h1 className="font-weight-bold mb-3">
                  Become an Investor at AqarChain
                </h1>
                <ul className="investorDataList">
                  <li>
                    <div>
                      <h4 className="font-weight-bold">Make a User Account.</h4>
                      <p className="text-justify">
                        Sign up for a free account to have access to property
                        details and a customized dashboard.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h4 className="font-weight-bold">
                        Start putting money into it now.
                      </h4>
                      <p className="text-justify">
                        Look over the available properties at AqarChain and make
                        a decision.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h4 className="font-weight-bold">Make a Living.</h4>
                      <p className="text-justify">
                        When your rent is paid, you will get your periodic
                        dividends.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h4 className="font-weight-bold">
                        Take Pleasure in Your Earnings.
                      </h4>
                      <p className="text-justify">
                        Reinvest to increase your profits, or withdraw to enjoy
                        your money!
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Section2 />
      <section id="investor-section_6">
        <div
          id="landing-section_5-fractional_ownership"
          className="container-lg">
          <div className="row no-gutters">
            <div className="col-lg-6 text-center text-md-left">
              <div className="p-4 p-md-6 pr-md-9 mx-3 has-box-shadow bg-dots-variant-1 bg-position-top-right-offset bg-repeat-no-repeat">
                <h1 className="font-weight-bold mb-3">
                  Why Invest in Real Estate?
                </h1>
                <p>
                  It&#39;s a low-volatility asset type that pays out
                  consistently.
                </p>
                <ul className="realEstateDataList">
                  <li>
                    <div className="icon">
                      <img
                        src={require('../../../assets/images/best-performing .svg')}
                      />
                    </div>
                    <div>
                      <h4 className="font-weight-bold">
                        Timeline of the Best-Performing Asset Class
                      </h4>
                      <p className="text-justify">
                        Real estate has long been regarded as the best
                        investment for accumulating wealth.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img
                        src={require('../../../assets/images/passive-income.svg')}
                      />
                    </div>
                    <div>
                      <h4 className="font-weight-bold">Passive Income</h4>
                      <p className="text-justify">
                        Renting out home offers a recurrent income stream in
                        real estate.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img
                        src={require('../../../assets/images/stability.svg')}
                      />
                    </div>
                    <div>
                      <h4 className="font-weight-bold">Stability</h4>
                      <p className="text-justify">
                        Over time, real estate values are less volatile than
                        stocks and other financial assets.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img
                        src={require('../../../assets/images/residential.svg')}
                      />
                    </div>
                    <div>
                      <h4 className="font-weight-bold">Residential</h4>
                      <p className="text-justify">
                        Housing has never been more relevant than it is now,
                        thanks to Covid-19.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <img
                alt=""
                className="img-fluid w-90"
                style={{padding: 0}}
                src={require('../../../assets/images/invest-banner.png')}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Investments;
