import React from 'react';
import {connect} from 'react-redux';
import {
  saveFilter,
  searchProperty,
  resetFilters,
  getCountryData,
  getStateData,
  getCityData,
} from '../../redux/actions';
import IsLoadingHOC from './IsLoadingHOC';

import {Redirect, Link} from 'react-router-dom';
import FilterOption from './FilterOption';
class FilterForm extends React.Component {
  constructor(props) {
    super(props);
    // let propertyType = '';
    // if (this.props.filters.buy) propertyType = 'BUY';
    // if (this.props.filters.rent) propertyType = 'RENT';

    this.state = {
      products: this.props.products,
      activePage: 1,
      totalItemsCount: 200,
      itemCountPerpage: 5,
      title: '',
      country: '',
      state: '',
      city: '',
      selectedCountry: '',
      selectedState: '',
      selectedCity: '',
      type: '',
      buy: false,
      rent: false,
    };
  }

  searchPropertyByText = text => {
    const {searchProperty, setLoading, filters} = this.props;
    filters['title'] = text;
    this.setState({type: ''});
    setLoading(true);
    searchProperty({
      pageNumber: 1,
      limit: 5,
      filters: {
        title: text,
      },
      sort: -1,
    })
      .then(response => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  findByPropertyFor = type => {
    const {searchProperty, setLoading} = this.props;
    setLoading(true);
    searchProperty({
      pageNumber: 1,
      limit: 5,
      filters: {
        propertyFor: type,
      },
      sort: -1,
    })
      .then(response => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  makeTypeSearch = e => {
    this.setState({type: e});
    if (e === 'BUY') {
      this.props.filters.buy = true;
      this.findByPropertyFor('BUY');
    } else if (e === 'RENT') {
      this.props.filters.rent = true;
      this.findByPropertyFor('RENT');
    }
  };

  componentDidMount() {
    // this.searchPropertyByText('');
    this.setState({
      title:
        this.props.filters.title?.length !== 0 ? this.props.filters.title : '',
    });
  }

  render() {
    const {title, selectedCity, selectedCountry, selectedState} = this.state;
    const {config, filters, stateData, cityData} = this.props;
    const {pathname} = window.location;
    return (
      <section id="search-section_1">
        <div id="search-section_1-form">
          <div id="search-form">
            <div id="search-form-top">
              <div id="search-form-top-buttons">
                <button
                  onClick={() => this.makeTypeSearch('RENT')}
                  className={`btn ${
                    this.state.type === 'RENT' ? `btn-gradient` : ''
                  }`}
                  type="button">
                  Rent
                </button>
                <button
                  onClick={() => this.makeTypeSearch('BUY')}
                  className={`btn ${
                    this.state.type === 'BUY' ? `btn-gradient` : ''
                  }`}
                  type="button">
                  Buy
                </button>
              </div>
              <div id="search-bar">
                <div className="input-group">
                  <input
                    id="search"
                    type="text"
                    value={title}
                    onChange={e => {
                      this.setState({title: e.target.value});
                      // console.log('searchPropertyByText', e.target.value);
                    }}
                    className="form-control"
                    placeholder="Search your next property ..."
                    aria-label="search"
                    aria-describedby="search"
                  />
                  <div className="input-group-append">
                    <Link
                      to={`/search-view?q=${title}`}
                      onClick={() => this.searchPropertyByText(title)}
                      className="btn btn-gradient"
                      type="button">
                      Quick Search
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {pathname === '/' && <FilterOption data={this.props} />}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const {app} = state;
  return {
    config: app.configData,
    countryData: app.countryData,
    filters: app.filters,
    stateData: app.stateData,
    cityData: app.cityData,
    products: app.products,
  };
};

export default connect(mapStateToProps, {
  saveFilter,
  searchProperty,
  resetFilters,
  getCountryData,
  getStateData,
  getCityData,
})(IsLoadingHOC(FilterForm));
