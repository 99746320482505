import {
  ADD_NEW_PROPERTY,
  USER_DATA_SAVE,
  USER_WISHLISED_PROPERTY,
  USER_UPLOADED_PROPERTY,
} from './types';
import {aqarChain} from '../../aqarchain';

// for all tpye of users
export const getUserProfile = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.get('/api/auth/getProfile', data);
    if (response.status === 1) {
      dispatch({type: USER_DATA_SAVE, payload: response.data[0]});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};
// update user profile
export const updatProfile = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/auth/updateProfile', data);
    console.log('update profile', response);
    if (response.status === 1) {
      dispatch({type: USER_DATA_SAVE, payload: response.data});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

// update company profile
export const updatProfileCompany = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/company/edit/profile/', data);
    if (response.status === 1) {
      dispatch({type: USER_DATA_SAVE, payload: response.data});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};
export const updatProfileAgent = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/agent/update/profile/', data);
    if (response.status === 1) {
      dispatch({type: USER_DATA_SAVE, payload: response.data});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

// get user properties

export const getUserProperties = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/property/getList', data);
    if (response.status === 1) {
      dispatch({type: USER_UPLOADED_PROPERTY, payload: response.data});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

// get user saved properties

export const getUserSavedProperties = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/wishlist/get', data);
    if (response.status === 1) {
      console.log('response.data', response.data);
      dispatch({type: USER_WISHLISED_PROPERTY, payload: response.data});
      resolve(response);
    } else {
      reject(response.message);
    }
  });
};

// Add new Property
export const addNewProperty = data => async dispatch => {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.multipart('/api/property/', data);
    if (response.status === 1) {
      // dispatch({type: ADD_NEW_PROPERTY, payload: response.data});
      resolve(response);
    } else {
      reject(response);
    }
  });
};

//remove Property from user saved
export const deleteSavedProperties = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.delete('/api/property/' + data);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};

//remove user Wishlisted properties..

export const removeWishlistedProperties = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/wishlist/remove/property/', {
      property_id: data,
    });
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};
