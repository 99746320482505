import React from 'react';
import {Link} from 'react-router-dom';
import TableBox from '../../pages/Dashboard/Property/TableBox';
import PaginationCustom from '../PaginationCustom';

const TableView = ({
  propertyData,
  handleShowNumberOfRecords,
  handlePagination,
  activePage,
  totalItemsCount,
  itemCountPerpage,
  removeProperty,
}) => {
  return (
    <>
      <div className="dashboard-table-container table-responsive">
        <TableBox>
          {propertyData.map(item => {
            return (
              <tr key={item._id}>
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td>{item.property_type[0].type_title}</td>
                <td>{item.belongTo[0].companyName}</td>
                <td>
                  {item.price} {item.currency}
                </td>
                <td>
                  <Link
                    className="btn btn-blue btn-sm btn-rounded-sm mb-0 mr-2"
                    to={{
                      pathname: `/property-detail/${item._id}`,
                      query: item,
                    }}>
                    View
                  </Link>
                  <button
                    onClick={() => removeProperty(item._id)}
                    className="btn btn-blue btn-sm btn-rounded-sm mb-0">
                    Remove
                  </button>
                </td>
              </tr>
            );
          })}
        </TableBox>
      </div>
      {propertyData.length !== 0 && (
        <PaginationCustom
          handleShowNumberOfRecords={handleShowNumberOfRecords}
          handlePagination={handlePagination}
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          itemCountPerpage={itemCountPerpage}
        />
      )}
    </>
  );
};

export default TableView;
