import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {searchBasic} from '../../../redux/actions';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {defaultOptions} from '../../../config';
import {Link} from 'react-router-dom';

const Section4 = props => {
  const [data, setData] = useState([]);

  const responsive = {
    0: {
      items: 1,
    },
    550: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  };

  useEffect(() => {
    props
      .searchBasic({
        pageNumber: 1,
        limit: 10,
        sort: -1,
        filters: {
          featureds: true,
        },
      })
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.log('feature error', error);
      });
  }, [props]);

  return (
    <section id="landing-section_4">
      <div id="landing-section_4-featured_properties" className="container-lg">
        <div className="row d-flex align-items-center no-gutters">
          <div className="col-lg-3 text-center text-lg-left">
            <h1 className="mb-3 mb-lg-0 ml-lg-3">
              Featured <span className="font-weight-bold">Properties</span>
            </h1>
          </div>
          <div className="col-lg-9 text-center text-lg-left ">
            <div id="featured_properties_carousel">
              {data.length > 0 ? (
                <OwlCarousel
                  className="owl-carousel owl-theme owl-dots-centered"
                  loop
                  margin={0}
                  items={3}
                  responsive={responsive}>
                  {data.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <div className="featured-property">
                          <div className="featured-property-images d-flex justify-content-center">
                            <Link to={`/property-detail/${item._id}`}>
                              <span className="">
                                <img
                                  src={`${defaultOptions.baseUrl}/api${item.cover}`}
                                  alt={item.cover}
                                />
                              </span>
                            </Link>
                          </div>
                          <div className="card property-card featured-property-card has-box-shadow">
                            <div className="card-body">
                              <div className="property-id">
                                <p className="mb-1">
                                  Property ID:{' '}
                                  <span className="font-weight-bold">
                                    {item.id}
                                  </span>
                                </p>
                              </div>
                              <div className="property-meta">
                                <p className="d-flex justify-content-start">
                                  {item.hasOwnProperty('property_type_data') ? (
                                    <span className="mr-2">
                                      {item.property_type_data[0].type_title}
                                    </span>
                                  ) : null}
                                  <span className="mr-auto font-weight-bold">
                                    {item.currency}{' '}
                                    {new Intl.NumberFormat('en-US', {
                                      maximumSignificantDigits: 3,
                                    }).format(item.price)}
                                  </span>
                                </p>
                              </div>
                              <div className="property-agent">
                                <img
                                  className="property-agent-image"
                                  src={
                                    !item.belongTo[0]
                                      ? 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
                                      : `${defaultOptions.baseUrl}/api/${item.belongTo[0].profile}`
                                  }
                                  alt=""
                                />
                                <span className="property-agent-name">
                                  {!item.belongTo[0]
                                    ? 'NA'
                                    : item.belongTo[0].role}
                                  :{' '}
                                  <span className="font-weight-bold text-capitalize">
                                    {!item.belongTo[0]
                                      ? 'NA'
                                      : item.belongTo[0].role === 'COMPANY'
                                      ? item.belongTo[0].companyName
                                      : item.belongTo[0].first_name}
                                  </span>
                                </span>
                              </div>
                              <div className="property-specifications">
                                <p className="mb-0 d-flex justify-content-between">
                                  <span className="property-specification">
                                    <img
                                      className="property-specification-icon property-specification-icon-bedrooms"
                                      src="/images/icon-property_specification-bedrooms.svg"
                                      alt=""
                                    />{' '}
                                    {item.propertyFeature.number_of_beds} Beds
                                  </span>
                                  <span className="property-specification">
                                    <img
                                      className="property-specification-icon property-specification-icon-bathrooms"
                                      src="/images/icon-property_specification-bathrooms.svg"
                                      alt=""
                                    />{' '}
                                    {item.propertyFeature.number_of_baths} Baths
                                  </span>
                                  <span className="property-specification">
                                    <img
                                      className="property-specification-icon property-specification-icon-area"
                                      src="/images/icon-property_specification-area.svg"
                                      alt=""
                                    />{' '}
                                    {item.propertyFeature.propertyArea}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(null, {searchBasic})(Section4);
