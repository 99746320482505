import {
  SAVE_LANGUAGE,
  SAVE_FILTER,
  SAVE_PRODUCTS,
  LOGOUT,
  SAVE_VARIFY_TOKEN,
  REMOVE_VARIFY_TOKEN,
  ADD_NEW_PROPERTY,
  USER_WISHLISED_PROPERTY,
  USER_UPLOADED_PROPERTY,
} from '../actions/types';
let initialState = {
  user: {},
  token: '',
  varifyToken: '',
  webaccessToken: '',
  language: 'en',
  configData: {},
  propertyList: [],
  filters: {},
  products: [],
  userWishlisted: [],
  userUploaded: [],
  viewType: 'list',
  countryData: [],
  stateData: [],
  cityData: [],
};
export default function(state = initialState, action) {
  console.log(action.type, action.payload);

  switch (action.type) {
    case SAVE_LANGUAGE:
      return {...state, language: action.payload};
    case 'USER_DATA_SAVE':
      return {...state, user: {...state.user, ...action.payload}};
    case 'REMOVE_USER_DATA':
      return {...state, user: {}, token: ''};
    case 'SAVE_TOKEN':
      return {...state, token: action.token};
    case SAVE_VARIFY_TOKEN:
      return {...state, varifyToken: action.payload};
    case REMOVE_VARIFY_TOKEN:
      return {...state, varifyToken: ''};
    case 'REMOVE_TOKEN':
      return {...state, token: action.token};
    case 'SAVE_CONFIG_DATA':
      return {...state, configData: action.payload};
    case LOGOUT:
      return {...state, user: {}, token: ''};
    case 'PROPERTYLIST':
      return {...state, propertyList: action.payload};
    case SAVE_FILTER:
      return {...state, filters: {...state.filters, ...action.payload}};
    case SAVE_PRODUCTS:
      return {...state, products: action.payload};
    case ADD_NEW_PROPERTY:
      return {...state, products: action.payload};
    case USER_WISHLISED_PROPERTY:
      return {...state, userWishlisted: action.payload};
    case USER_UPLOADED_PROPERTY:
      return {...state, userUploaded: action.payload};
    case 'RESET_FILTER':
      return {...state, filters: {}};
    case 'VIEW_TYPE':
      return {...state, viewType: action.payload};
    case 'COUNTRY_DATA':
      return {...state, countryData: action.payload};
    case 'SAVE_ACCESS_CODE':
      localStorage.setItem('access_token', action.payload);
      return {
        ...state,
        webaccessToken: action.payload,
      };
    case 'STATE_DATA':
      return {...state, stateData: action.payload};
    case 'CITY_DATA':
      return {...state, cityData: action.payload};
    default:
      return state;
  }
}
