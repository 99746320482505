import React from 'react';

const PrivacyPolicy = () => {
  const style1 = {
    maxWidth: '1200px',
    margin: '4rem auto',
    marginBottom: '5rem',
    padding: '1rem',
  };
  return (
    <div className="privacy-policy-container" style={style1}>
      <p class="mb-4 text-justify">
        <h1 class="font-weight-bold text-center mb-5">Privacy Policy</h1>
        Regarding any information we may acquire while operating our website, it
        is Aqar Chain's policy to protect your privacy. This Privacy Statement
        applies to https://aqarchain.io/ (hereinafter referred to as "us," "we,"
        or "Aqar Chain"). We respect your right to privacy and are committed to
        protecting any personally identifiable information you provide to us
        through the Website. This privacy policy (the "Privacy Policy") explains
        what information may be collected on our website, how we use it, and
        when we may share it with third parties. This Privacy Policy only
        applies to information gathered through the website not from any other
        source.
      </p>
      <p className="mb-4 text-justify">
        This Privacy Policy, as well as the Terms and Conditions posted on our
        website, outline the general principles and policies that govern your
        use of our website. You may be needed to agree to additional terms and
        conditions based on your behaviour while visiting our website.
      </p>
      <h3 className="mb-3">Website Visitors</h3>
      <p className="mb-4 text-justify">
        Aqar Chain, like other website operators, collects
        non-personally-identifying information of the kind that web browsers and
        servers typically make available, such as browser type, language
        preference, referring site, and the date and time of each visitor
        request. The goal of collecting non-personally identifying information
        is for Aqar Chain to better understand how its visitors utilise its
        website. Aqar Chain may share non-personally-identifying information in
        the aggregate from time to time, for example, by issuing a report on
        website usage trends.
      </p>
      <p className="mb-4 text-justify">
        For logged-in users and those posting comments on Aqar Chain blog
        articles, Aqar Chain gathers potentially personally-identifying
        information such as Internet Protocol (IP) addresses. Aqar Chain only
        uses and discloses logged in user and commenter IP addresses under the
        same conditions as it uses and discloses information.
      </p>
      <p className="mb-4 text-justify">
        If you use/access this website, you are presumed to have accepted this
        privacy policy. If you do not agree to any part of this policy or the
        policy in its entirety, you must leave this website immediately.
      </p>
      <h3 className="mb-3">Security</h3>
      <p className="mb-4 text-justify">
        We care about the security of your Personal Information but keep in mind
        that no method of Internet transmission or computer storage is
        completely safe. While we use commercially reasonable efforts to protect
        your Personal Information, we cannot guarantee its complete security.
      </p>
      <h3 className="mb-3">Advertisements</h3>
      <p className="mb-4 text-justify">
        Advertisements on our site may be delivered to users by advertising
        partners, who may place cookies on their computers. These cookies enable
        the ad server to recognise your computer each time you see an online
        advertisement, allowing them to accumulate information about you and
        others who use your computer. This information enables ad networks,
        among other things, to provide personalised advertisements that they
        believe will be of most interest to you. This Privacy Policy only
        applies to Aqar Chain's use of cookies; it does not apply to any
        advertisers' use of cookies.
      </p>
      <h3 className="mb-3">Links To External Sites</h3>
      <p className="mb-4 text-justify">
        Links to third-party websites that are not under our control may appear
        on our Service. You will be routed to the third-website party's if you
        click on a thirdparty link. You should read the privacy policies and
        terms and conditions on every website you visit. Any third-party sites,
        products, or services, including their content, privacy policies, or
        practises, are outside our control and accountability.
      </p>
      <p className="mb-4 text-justify">
        This Privacy Policy, as well as the Terms and Conditions posted on our
        website, outline the general principles and policies that govern your
        use of our website. You may be needed to agree to additional terms and
        conditions based on your behaviour while visiting our website.
      </p>
      <h3 className="mb-3">Aggregated Statistics</h3>
      <p className="mb-4 text-justify">
        Aqar Chain may gather information about how users use its website. This
        information may be shown publicly or shared with others by Aqar Chain.
        Aqar Chain, on the other hand, does not reveal any of your personally
        identifiable information.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
