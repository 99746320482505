import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  saveFilter,
  searchProperty,
  resetFilters,
  getCountryData,
  getStateData,
  getCityData,
} from '../../redux/actions';
import IsLoadingHOC from './IsLoadingHOC';
import Select from 'react-select';
import {
  optionsCountry,
  listedByOption,
  currencyOption,
  viewTypeOption,
} from '../../config/constant';
import { Link } from 'react-router-dom';
const FilterOption = props => {
  const [filterSelectedData, setFilterSelectedData] = useState({
    selectedCountry: '',
    selectedState: '',
    selectedCity: '',
  });

  const {
    config,
    filters,
    stateData,
    cityData,
    getStateData,
    getCityData,
    saveFilter,
    resetFilters,
  } = props;

  const selectInputRef = useRef();

  const minPrice = [
    { value: 'AED 1000', label: 'AED 1000' },
    { value: 'AED 2000', label: 'AED 2000' },
    { value: 'AED 3000', label: 'AED 3000' },
    { value: 'AED 4000', label: 'AED 4000' },
    { value: 'AED 5000', label: 'AED 5000' },
  ];

  const maxPrice = [
    { value: 'AED 10000', label: 'AED 10000' },
    { value: 'AED 20000', label: 'AED 20000' },
    { value: 'AED 30000', label: 'AED 30000' },
    { value: 'AED 40000', label: 'AED 40000' },
    { value: 'AED 50000', label: 'AED 50000' },
  ];

  const minArea = [
    { value: '1000 Sq. ft', label: '1000 Sq. ft' },
    { value: '2000 Sq. ft', label: '2000 Sq. ft' },
    { value: '3000 Sq. ft', label: '3000 Sq. ft' },
    { value: '4000 Sq. ft', label: '4000 Sq. ft' },
    { value: '5000 Sq. ft', label: '5000 Sq. ft' },
  ];

  const maxArea = [
    { value: '10000 Sq. ft', label: '10000 Sq. ft' },
    { value: '20000 Sq. ft', label: '20000 Sq. ft' },
    { value: '30000 Sq. ft', label: '30000 Sq. ft' },
    { value: '40000 Sq. ft', label: '40000 Sq. ft' },
    { value: '50000 Sq. ft', label: '50000 Sq. ft' },
  ];

  const filterSelect = async (selectedOption, name) => {
    const filters = props.filters;
    switch (name) {
      case "country": {
        getStateData({ countryId: selectedOption.value });
        setFilterSelectedData({ selectedCountry: selectedOption });
        props.filters[name] = selectedOption.value;
      } break;
      case "state": {
        await getCityData({ stateId: selectedOption.value });
        setFilterSelectedData({ selectedState: selectedOption });
        props.filters[name] = selectedOption.value;
      } break;
      case "city": {
        setFilterSelectedData({ selectedCity: selectedOption });
        props.filters[name] = selectedOption.value;
      } break;
      case "property_type": {
        props.filters[name] = selectedOption.value;
      } break;
      case "minPrice": {
        props.filters[name] = selectedOption.value;
      } break;
      case "maxPrice": {
        props.filters[name] = selectedOption.value;
      } break;
    }
    saveFilter(filters);
  };

  const reset = () => {
    resetFilters();
    setFilterSelectedData({
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
    });
    selectInputRef.current.select.setValue('');
  };

  return (
    <div id="search-form-bottom">
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <Select
              className="select-box"
              value={filterSelectedData.selectedCountry}
              options={optionsCountry}
              placeholder="Select Country"
              onChange={seletedOption => filterSelect(seletedOption, 'country')}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <Select
              className="select-box"
              value={filterSelectedData.selectedState}
              options={stateData}
              placeholder="Select State"
              onChange={seletedOption => filterSelect(seletedOption, 'state')}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <Select
              className="select-box"
              value={filterSelectedData.selectedCity}
              options={cityData}
              placeholder="Select City"
              onChange={seletedOption => filterSelect(seletedOption, 'city')}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <Select
              ref={selectInputRef}
              className="select-box"
              value={
                filters?.property_type_selectedfilters &&
                filters.property_type_selected
              }
              options={
                config?.propertyTypes
                  ? config.propertyTypes.map(
                    ({ _id: value, type_title: label, ...rest }) => ({
                      value,
                      label,
                      ...rest,
                    }),
                  )
                  : []
              }
              placeholder="Property Type"
              onChange={seletedOption =>
                filterSelect(seletedOption, 'property_type')
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label className="text-muted">Price Range</label>
                <Select
                  ref={selectInputRef}
                  className="select-box"
                  value={
                    filters?.minPrice_selected && filters.minPrice_selected
                  }
                  options={minPrice}
                  placeholder="Min"
                  onChange={seletedOption =>
                    filterSelect(seletedOption, 'minPrice')
                  }
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="form-group" style={{ marginTop: '2rem' }}>
                <Select
                  ref={selectInputRef}
                  className="select-box"
                  value={
                    filters?.maxPrice_selected && filters.maxPrice_selected
                  }
                  options={maxPrice}
                  placeholder="Max"
                  onChange={seletedOption =>
                    filterSelect(seletedOption, 'maxPrice')
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label className="text-muted">Property Area Range</label>
                <Select
                  ref={selectInputRef}
                  className="select-box"
                  value={filters?.minArea_selected && filters.minArea_selected}
                  options={minArea}
                  placeholder="Min"
                  onChange={seletedOption =>
                    filterSelect(seletedOption, 'minArea')
                  }
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="form-group" style={{ marginTop: '2rem' }}>
                <Select
                  ref={selectInputRef}
                  className="select-box"
                  value={filters?.maxArea_selected && filters.maxArea_selected}
                  options={maxArea}
                  placeholder="Min"
                  onChange={seletedOption =>
                    filterSelect(seletedOption, 'maxArea')
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="col-lg-6 offset-lg-3">
          <div id="search-form-bottom-buttons">
            <Link
              to="/search-view"
              className="btn btn-gradient-secondary"
              type="button">
              Find
            </Link>
            <button
              onClick={() => reset()}
              className="btn btn-gradient-secondary"
              type="button">
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  const { app } = state;
  return {
    config: app.configData,
    countryData: app.countryData,
    filters: app.filters,
    stateData: app.stateData,
    cityData: app.cityData,
  };
};

export default connect(mapStateToProps, {
  saveFilter,
  searchProperty,
  resetFilters,
  getCountryData,
  getStateData,
  getCityData,
})(IsLoadingHOC(FilterOption, 'Loading ...'));
