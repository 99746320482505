import React from 'react';
import styled from 'styled-components';

const Img = styled.img`
  width: 30px;
  height: 40px;
`;
const Marker = ({text}) => {
  return (
    <>
      <div
        style={{
          color: 'white',
          padding: '15px 10px',
          display: 'inline-flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '100%',
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          top: '50%',
          left: ' 0',
        }}>
        <Img src={require('../../../assets/images/marker.png')} alt="" />
      </div>
      <div></div>
    </>
  );
};

export default Marker;
