import React from 'react';
import {useLocation, Link} from 'react-router-dom';
const CompanyMenu = () => {
  let location = useLocation();
  const CustomLink = ({url, className, id, title}) => {
    return (
      <li className="nav-item">
        <Link to={url} className={className} id={id}>
          {title}
        </Link>
      </li>
    );
  };
  return (
    <>
      <CustomLink
        url="/dashboard"
        className={`nav-link ${
          location.pathname === '/dashboard' ? 'active' : ''
        }`}
        id="company-nav-tab-dashboard-tab"
        title="Dashboard"
      />
      <CustomLink
        url="/dashboard/profile"
        className={`nav-link ${
          location.pathname === '/dashboard/profile' ? 'active' : ''
        }`}
        id="company-nav-tab-my-profile-tab"
        title="My Profile"
      />
      <CustomLink
        url="/dashboard/company-profile"
        className={`nav-link ${
          location.pathname === '/dashboard/company-profile' ? 'active' : ''
        }`}
        id="company-nav-tab-company-document-tab"
        title="Company Document"
      />
      <CustomLink
        url="/dashboard/save-properties"
        className={`nav-link ${
          location.pathname === '/dashboard/save-properties' ? 'active' : ''
        }`}
        id="user-dashboard-nav-tab-my-saved-properties-tab"
        title="My Saved Properties"
      />
      <CustomLink
        url="/dashboard/property-listing"
        className={`nav-link ${
          location.pathname === '/dashboard/property-listing' ? 'active' : ''
        }`}
        id="company-nav-tab-properties-uploaded-tab"
        title="Properties Uploaded"
      />
      <CustomLink
        url="/dashboard/agent"
        className={`nav-link ${
          location.pathname === '/dashboard/agent' ? 'active' : ''
        }`}
        id="company-nav-tab-agent-management-tab"
        title="Agent Management"
      />
      <CustomLink
        url="/dashboard/enquery"
        className={`nav-link ${
          location.pathname === '/dashboard/enquery' ? 'active' : ''
        }`}
        id="company-nav-tab-enquires-tab"
        title="Enquires"
      />
    </>
  );
};

export default CompanyMenu;
