import React from "react";
import {optionsCountry} from "../../config/constant";
import Select from "react-select";
const Country = ({onChange, selected}) => {
	return (<Select 
		className="select-box" 
		value={selected}
		options={optionsCountry}
		placeholder="Select Country"
		onChange={(e) => onChange(e)}
	/>);
	
};

export default Country;
