import React from 'react';
import {useSelector} from 'react-redux';
import {defaultOptions} from '../../config';
const UserProfile = () => {
  const result = useSelector(state => state.app.user);
  return (
    <>
      {!result.profile ? (
        <img
          className="user-profile-pic"
          src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
          alt={`${result.first_name} avatar`}
        />
      ) : (
        <img
          className="user-profile-pic"
          src={`${defaultOptions.baseUrl}/api${result.profile}`}
          alt={`${result.first_name} avatar`}
        />
      )}
    </>
  );
};

export default UserProfile;
