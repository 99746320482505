import React from 'react';
import {Pannellum} from 'pannellum-react';
import img from '../../assets/images/360-1.jpg';

const View360 = () => {
  return (
    <Pannellum
      width="100%"
      height="600px"
      image={img}
      pitch={5}
      yaw={180}
      hfov={110}
      autoLoad
      showZoomCtrl={true}></Pannellum>
  );
};

export default View360;
