import React, {useState, useEffect} from 'react';
import DashboardComponent from '../Dashboard.component';
import PaginationCustom from '../../../common/PaginationCustom';
import FilterBarBox from '../../../common/FilterBarBox';
import IsLoadingHOC from '../../../common/IsLoadingHOC';
import TableBox from './TableBox';
import {connect, useSelector, useDispatch} from 'react-redux';
import {
  getUserSavedProperties,
  removeWishlistedProperties,
} from '../../../../redux/actions';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import MapView from '../../../common/ProppertyView/MapView';
import TableView from '../../../common/ProppertyView/TableView';
import CardView from '../../../common/ProppertyView/CardView';

const SaveProperties = props => {
  const {
    setLoading,
    getUserSavedProperties,
    removeWishlistedProperties,
    products,
    userWishlisted,
  } = props;
  const state = useSelector(state => state);
  const {viewType} = state.app;
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotolItemsCount] = useState(200);
  const [itemCountPerpage, setItemCountPerPage] = useState(5);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const getList = () => {
    setLoading(true);
    getUserSavedProperties({
      pageNumber: activePage,
      limit: itemCountPerpage,
      sort: -1,
    })
      .then(response => {
        setLoading(false);
        dispatch({type: 'USER_WISHLISED_PROPERTY', payload: response.data});
        setData(response.data);
        setTotolItemsCount(response.count);
      })
      .catch(error => {
        console.error('error', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getList();
  }, [activePage, itemCountPerpage]);

  const handlePagination = async activePage => {
    await setActivePage(activePage);
  };
  const handleShowNumberOfRecords = async itemCountPerpage => {
    await setItemCountPerPage(itemCountPerpage);
  };

  const dataCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    minHeight: '500px',
    backgroundColor: '#F0F2F7',
  };

  const removeProperty = id => {
    if (id !== undefined || id !== null) {
      setLoading(true);
      removeWishlistedProperties(id)
        .then(
          response => {
            setLoading(false);
            toast.success(response.message);
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          },
          error => {
            setLoading(false);
            toast.success(error.message);
          },
        )
        .catch(error => {
          toast.success('Something Went Worrg');
        });
    }
  };
  return (
    <DashboardComponent>
      <div
        className="tab-pane"
        id="company-nav-tab-properties-uploaded"
        role="tabpanel"
        aria-labelledby="company-nav-tab-properties-uploaded-tab">
        <div className="text-center has-border-radius text-lg-left p-4 p-lg-5 has-box-shadow">
          <div className="row">
            <div className="col-lg-9 offset-lg-3 col-12">
              <FilterBarBox />
            </div>
          </div>
          {viewType === 'list' && (
            <>
              {userWishlisted.length !== 0 ? (
                <TableView
                  propertyData={userWishlisted}
                  removeProperty={removeProperty}
                  handleShowNumberOfRecords={handleShowNumberOfRecords}
                  handlePagination={handlePagination}
                  activePage={activePage}
                  totalItemsCount={totalItemsCount}
                  itemCountPerpage={itemCountPerpage}
                />
              ) : (
                <div style={dataCenter}>
                  You don't have any saved property yet.{' '}
                  <Link
                    to="/search-view"
                    className="form-submit btn btn-sm btn-gradient-secondary">
                    Browse the list
                  </Link>{' '}
                  and bookmark now
                </div>
              )}
            </>
          )}
          {viewType === 'card' && (
            <>
              {userWishlisted.length !== 0 ? (
                <CardView
                  propertyData={userWishlisted}
                  handleShowNumberOfRecords={handleShowNumberOfRecords}
                  handlePagination={handlePagination}
                  activePage={activePage}
                  totalItemsCount={totalItemsCount}
                  itemCountPerpage={itemCountPerpage}
                />
              ) : (
                <div style={dataCenter}>
                  You don't have any saved property yet.{' '}
                  <Link
                    to="/search-view"
                    className="form-submit btn btn-sm btn-gradient-secondary">
                    Browse the list
                  </Link>{' '}
                  and bookmark now
                </div>
              )}
            </>
          )}
          {viewType === 'map' && (
            <>
              {data.length !== 0 ? (
                <MapView propertyData={data} />
              ) : (
                <div style={dataCenter}>
                  You don't have any saved property yet.{' '}
                  <Link
                    to="/search-view"
                    className="form-submit btn btn-sm btn-gradient-secondary">
                    Browse the list
                  </Link>{' '}
                  and bookmark now
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </DashboardComponent>
  );
};

const mapStateToProps = state => {
  const {viewType, products, userWishlisted} = state.app;
  console.log('products', state.app);
  return {
    viewType: viewType,
    products: products,
    userWishlisted: userWishlisted,
  };
};

export default connect(mapStateToProps, {
  getUserSavedProperties,
  removeWishlistedProperties,
})(IsLoadingHOC(SaveProperties, 'Loading .....'));
