import React from 'react';
import FilterSection from './FilterSection';
import FooterHome from './FooterHome';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';

const Home = () => {
  return (
    <>
      <FilterSection />
      <Section2 />
      <Section3 />
      <Section4 />
      <hr />
      {/* <Section5 /> */}
      <Section6 />
      <FooterHome />
    </>
  );
};

export default Home;
