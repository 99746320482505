import React ,{ useState } from 'react';
import axios from 'axios'
import {defaultOptions} from '../../../config';

import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const Resetpassword = () => {
 
    const [pass, setpassword] = useState();
    const [confirmPass, setconfirmPassword] = useState();

    let history = useHistory();
  
    const steplast = async(password, confirmPassword) => {
      console.log("in");
  
      const params = {
        "password":password,
        "passwordConfirm":confirmPassword
    }
      console.log(params)
      var str = window.location.pathname;
            var res = str.substr(7);
            console.log(res)
            if(password.length > 6){
              if(password===confirmPassword){
                await axios
                  //  .post("https://api.aqarchain.com/api/api/auth/resetpassword/"+res, params)
                   .post(`${defaultOptions.baseUrl}api/auth/resetpassword/${res}`, params)
                   .then((res) => {
                       console.log(res)
                       history.push('/user-registration')
           
                   })
                   .catch((err) => {
                     console.error(err);
                     toast.error(err);
                   });
                 }else{
                   toast.error("Password and Confirm password not matched")
                 }
            }else{
              console.log(password.length)
              toast.error("Password should be a minimum of six characters.")
            }
            
    } 
 
    return (
      <section id="user-registration-section_1">
        
        
     

        
          <div
            id="user-registration-section_1-signup-step4"
            className="container-lg">
            <div className="row no-gutters">
              <div className="col-xl-1 col-md-12">
                <div className="user-registration-card-bg">
                  <img
                    alt=""
                    className="p-4"
                    src="/images/dots-variant-1.svg"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                <div className="py-md-6 p-5 mx-3 has-box-shadow">
                  <div className="form-heading my-5">
                    <h3 className="font-weight-bold text-dark">
                      Reset your password
                    </h3>
                    <h3 className="font-weight-bold text-dark">
                      for seam less experience
                    </h3>
                  </div>
                  <form className="my-3">
                    <div className="form-group">
                      <input
                        
                        type="text"
                        className="form-control secondary-input"
                        id="email-id"
                        placeholder="Password"
                        onChange={(e) => {
                            console.log(e.target.value);
                            setpassword(e.target.value);
                          }}
                      />
                       <input
                        
                        type="text"
                        className="form-control secondary-input"
                        id="email-id"
                        placeholder="Confirm Password"
                        onChange={(e) => {
                            console.log(e.target.value);
                            setconfirmPassword(e.target.value);
                          }}
                      />
                  
                    </div>
                   
                   

                    
                  </form>
                  <button
                    id="signup-button"
                    className="btn btn-gradient-secondary w-100 my-5"
                    onClick={() => steplast(pass,confirmPass)}
                   >
                    Change Password
                  </button>
                  <ToastContainer/>
                </div>
              </div>
              <div className="col-lg-6 col-12 text-center text-lg-left">
                <div className="registration-artwork-top bg-dots-variant-1 bg-position-center-right-offset bg-repeat-no-repeat p-5">
                  <h2>Take a break from</h2>
                  <h2>financial work, and focus</h2>
                  <h2>
                    on{' '}
                    <span className="font-weight-bold">
                      what matters the most
                    </span>
                  </h2>
                </div>
                <div className="registration-artwork-bottom">
                  <img
                    alt=""
                    className="img-fluid w-90"
                    src="/images/User-Registration-Artwork.svg"
                  />
                </div>
              </div>
            </div>
          </div>
       
       

        
      </section>
    );
  }

export default Resetpassword;