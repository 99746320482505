import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useHistory, Link} from 'react-router-dom';
import cron from 'node-schedule';
import jwt from 'jsonwebtoken';

//Redux imports
import {useSelector, useDispatch} from 'react-redux';
import {connect} from 'react-redux';
import {login, logOut} from '../../../redux/actions';

const Login = ({registerStep}) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  let history = useHistory();

  const stateData = useSelector(state => state);
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (
      email === '' ||
      email === undefined ||
      password === '' ||
      password === undefined
    ) {
      alert('Email and passwork should not be empty');
    } else {
      const data = {email: email, password: password};
      //dispatch(login(data));
      login(data, dispatch)
        .then(
          response => {
            toast.success(response.message);
            if (response.data.role === 'USER') {
              history.push('/dashboard/profile');
            } else {
              history.push('/dashboard');
            }
            let {exp} = jwt.decode(response.data.token, {complete: false});
            if (exp) {
              cron.scheduleJob(new Date(exp * 1000), () => {
                dispatch(logOut());
              });
            }
          },
          err => {
            toast.error(err.message);
          },
        )
        .catch(error => {
          toast.error(error.message);
        });
    }
  };

  return (
    <div id="user-registration-section_1-signup-step6" className="container-lg">
      <div className="row no-gutters">
        <div className="col-xl-1 col-md-12">
          <div className="user-registration-card-bg">
            <img alt="" className="p-4" src="/images/dots-variant-1.svg" />
          </div>
        </div>
        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
          <div className="py-md-7 p-5 mx-3 has-box-shadow">
            <div className="form-heading my-5">
              <h3 className="font-weight-bold text-dark">
                Login or Signup to take the
              </h3>
              <h3 className="font-weight-bold text-dark">
                your Property Listing to a whole new level
              </h3>
            </div>
            <form className="my-4">
              <div className="form-group">
                <input
                  type="text"
                  onChange={e => setEmail(e.target.value)}
                  className="form-control secondary-input"
                  id="email-address"
                  placeholder="Mobile Number/Email ID"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  className="form-control secondary-input"
                  id="password2"
                  placeholder="Create Password"
                />
              </div>
              <div>
                <div className="form-check float-left mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="rememberMe"
                  />
                  <label
                    className="form-check-label font-weight-bold text-dark"
                    htmlFor="rememberMe">
                    Remember me
                  </label>
                </div>
                <span className="form-text text-muted text-center font-weight-bold text-dark float-right">
                  <Link to={'/forgotpassword'}>Forgot Password?</Link>
                </span>
              </div>
            </form>
            <button
              id="signup-button"
              className="btn btn-gradient-secondary w-100 my-6"
              onClick={() => onSubmit()}>
              Login
            </button>
            <small className="form-text text-muted text-center">
              Don't have an account?{' '}
              <span
                onClick={registerStep}
                className="font-weight-bold text-muted">
                <Link to={'#'}>Click here to register</Link>
              </span>
            </small>
          </div>
        </div>
        <div className="col-lg-6 col-12 text-center text-lg-left">
          <div className="registration-artwork-top bg-dots-variant-1 bg-position-center-right-offset bg-repeat-no-repeat p-5">
            <h2>Take a Break from Conventional</h2>
            <h2> listing methods and focus</h2>
            <h2>
              on <span className="font-weight-bold">what matters the most</span>
            </h2>
          </div>
          <div className="registration-artwork-bottom">
            <img
              alt=""
              className="img-fluid w-90"
              src="/images/User-Registration-Artwork.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {logOut})(Login);
