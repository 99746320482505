import React from 'react';
import {connect} from 'react-redux';
import {
  searchProperty,
  removeFromWhisList,
  removeLikeProperty,
} from '../../../redux/actions';
import GoogleMapReact from 'google-map-react';
import {defaultOptions} from '../../../config';
import IsLoadingHOC from '../../common/IsLoadingHOC';
import PaginationCustom from '../../common/PaginationCustom';
import {toast} from 'react-toastify';
import MapView from '../../common/ProppertyView/MapView';
import CardView from '../../common/ProppertyView/CardView';

class Section3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      totalItemsCount: 0,
      itemCountPerpage: 5,
      data: this.props.products,
    };
  }
  componentDidMount() {
    this.getList();
    // this.getWhisList();
    // this.tokenCheck();
  }
  getList = () => {
    const {activePage, totalItemsCount, itemCountPerpage} = this.state;
    const {searchProperty, setLoading} = this.props;
    setLoading(true);
    searchProperty({
      pageNumber: activePage,
      limit: itemCountPerpage,
      sort: -1,
      filters: {
        title: window.location.search.substring(3),
      },
    })
      .then(
        response => {
          // console.log('this.props', response);
          this.setState({data: response.data, totalItemsCount: response.count});
          setLoading(false);
        },
        erroe => {
          setLoading(false);
          console.log('erroe', erroe);
        },
      )
      .catch(error => {
        setLoading(false);
      });
  };

  handlePagination = async activePage => {
    await this.setState({activePage: activePage});
    this.getList();
  };
  handleShowNumberOfRecords = async itemCountPerpage => {
    await this.setState({itemCountPerpage: parseInt(itemCountPerpage)});
    this.getList();
  };

  render() {
    const {activePage, totalItemsCount, itemCountPerpage} = this.state;
    const {viewType, token, products} = this.props;
    // console.log('activePage', this.state);
    return (
      <section id="search-section_3">
        <div id="search-section_3-results" className="container-lg">
          <div className="row">
            <div className="col-lg-12">
              {viewType === 'list' && (
                <CardView
                  propertyData={products}
                  handleShowNumberOfRecords={this.handleShowNumberOfRecords}
                  handlePagination={this.handlePagination}
                  activePage={activePage}
                  totalItemsCount={totalItemsCount}
                  itemCountPerpage={itemCountPerpage}
                />
              )}
              {viewType === 'map' && <MapView propertyData={products} />}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const {products, viewType, token} = state.app;
  return {
    products: products,
    viewType: viewType,
    token,
  };
};

export default connect(mapStateToProps, {
  searchProperty,
  // removeFromWhisList,
  // removeLikeProperty,
})(IsLoadingHOC(Section3, 'Waiting ...'));
