import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {addToWishList} from '../../redux/actions';
import IsLoadingHOC from './IsLoadingHOC';

const PropertyWishList = props => {
  const {item, token, setLoading, addToWishList} = props;
  const [propertyWishListed, setPropertyWishListed] = useState(
    props.item.wishlisted,
  );
  useEffect(() => {}, [propertyWishListed]);

  const handleWishListClick = id => {
    setLoading(true);
    addToWishList({property_id: id})
      .then(
        response => {
          setLoading(false);
          toast.success(response.message);
          setPropertyWishListed(true);
        },
        error => {
          setLoading(false);
          toast.success(error.message);
        },
      )
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className="property-save">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20.96 25.62"
        id="icon-list_property-save"
        style={{width: '22px'}}
        onClick={() =>
          token
            ? handleWishListClick(item._id)
            : toast.success('Please Login to Like Property')
        }>
        <title>Save</title>
        <g id="icon-list_property-save-1" data-name="Layer 1">
          <g id="icon-list_property-like-2" data-name="Layer 2">
            <path
              fill={propertyWishListed ? '#FBB43C' : 'block'}
              d="M.6,25.47a1.16,1.16,0,0,0,1.18,0L10.48,20l8.7,5.44a1.17,1.17,0,0,0,1.78-1v-21A3.49,3.49,0,0,0,17.47,0h-14A3.49,3.49,0,0,0,0,3.49v21A1.15,1.15,0,0,0,.6,25.47Zm1.73-22A1.16,1.16,0,0,1,3.49,2.33h14a1.16,1.16,0,0,1,1.16,1.16V22.36L11.1,17.65a1.16,1.16,0,0,0-1.24,0L2.33,22.36Z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

const mapStateToProps = ({app}) => {
  const {token} = app;
  return {
    token,
  };
};
export default connect(mapStateToProps, {addToWishList})(
  IsLoadingHOC(PropertyWishList),
);
