export const defaultOptions = {
  //baseUrl: 'http://3.16.31.116:5000',
  //baseUrl: "https://api.aqarchain.com",
  // baseUrl: process.env.REACT_APP_BASEURL,
  // baseUrl: 'https://staging-api.aqarchain.com',
  baseUrl: 'https://api.aqarchain.com/',
  // apikey: 'ABCDEFGH987654321*&^%$@!SIGMA',
  // googleApiKey: 'AIzaSyDuNOlGipJ7UVFTUneufRW1FG8-1pQvJYo',
  googleGeoLocationKey: 'AIzaSyA3lp_UtCAE2SFYcsW5QyqAcEiEdbvDEv8',
  // stripeKey: 'sk_test_pCF33SZV2ApKfdUvWB6jykbr',
  localeMobile: {'+971': 'ar-AE', '+91': 'en-IN', '+966': 'ar-SA'},
};
