import React, {useState, useEffect} from 'react';
import DashboardComponent from '../Dashboard.component';
import Button from '../../../common/Button';
import {Link, useHistory} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import {getRegisterAgent} from '../../../../redux/actions';
import {toast} from 'react-toastify';
import IsLoadingHOC from '../../../common/IsLoadingHOC';
import FilterBarBox from '../../../common/FilterBarBox';
import PaginationCustom from '../../../common/PaginationCustom';
const AgentManagment = ({setLoading, getRegisterAgent}) => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/dashboard/agent/add');
  };
  const state = useSelector(state => state.app.user);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotolItemsCount] = useState(200);
  const [itemCountPerpage, setItemCountPerPage] = useState(5);
  const [data, setData] = useState([]);
  const getList = () => {
    setLoading(true);
    getRegisterAgent({
      pageNumber: activePage,
      limit: itemCountPerpage,
      sort: -1,
    })
      .then(response => {
        setLoading(false);
        setData(response.data);
        setTotolItemsCount(response.count);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getList();
  }, [activePage, itemCountPerpage]);

  const handlePagination = async activePage => {
    await setActivePage(activePage);
  };
  const handleShowNumberOfRecords = async itemCountPerpage => {
    await setItemCountPerPage(itemCountPerpage);
  };

  return (
    <DashboardComponent>
      <div
        className="tab-pane"
        id="company-nav-tab-agent-management"
        role="tabpanel"
        aria-labelledby="company-nav-tab-agent-management-tab">
        <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
          <div className="row d-lg-flex align-items-center">
            <div className="col-lg-3 col-12 my-lg-0 my-3">
              <Button
                title="Add Agent"
                handleClick={handleClick}
                classes="form-submit btn w-100 btn-gradient-secondary"
              />
            </div>
            <div className="col-lg-8 offset-lg-1 col-12">
              <div id="search-filter_options" className="mb-2 mb-lg-0">
                <div className="d-flex">
                  <div id="search-sort_by" className="search-filter_option">
                    <span className="mr-3">Sort By</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      className="icon-search-filter_options">
                      <title>Sort By</title>
                      <g
                        id="icon-search-filter_options-sort_by-1"
                        data-name="Layer 1">
                        <g
                          id="icon-search-filter_options-sort_by-2"
                          data-name="Layer 2">
                          <g
                            id="icon-search-filter_options-sort_by-3"
                            data-name="Layer 3">
                            <path d="M14.18,21.11,9.23,26.06V1.15a1.16,1.16,0,0,0-2.31,0V26.06L2,21.11A1.15,1.15,0,1,0,.34,22.74l6.92,6.92a1.14,1.14,0,0,0,.82.34,1.12,1.12,0,0,0,.81-.34l6.93-6.92a1.15,1.15,0,0,0,0-1.63,1.16,1.16,0,0,0-1.64,0Z" />
                            <path d="M29.66,7.26,22.74.34a1.15,1.15,0,0,0-1.63,0L14.18,7.26a1.15,1.15,0,0,0,0,1.63,1.16,1.16,0,0,0,1.64,0l4.95-5V28.85a1.16,1.16,0,0,0,2.31,0V3.94l5,5a1.14,1.14,0,0,0,.82.34,1.12,1.12,0,0,0,.81-.34,1.15,1.15,0,0,0,0-1.63Z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div id="search-filters" className="search-filter_option">
                    <span className="mr-3">Filter</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32.31 30"
                      className="icon-search-filter_options">
                      <title>Filter</title>
                      <g
                        id="icon-search-filter_options-filter-1"
                        data-name="Layer 1">
                        <g
                          id="icon-search-filter_options-filter-2"
                          data-name="Layer 2">
                          <g
                            id="icon-search-filter_options-filter-3"
                            data-name="Layer 3">
                            <path d="M28.85,11.54H3.46a1.16,1.16,0,0,1,0-2.31H28.85a1.16,1.16,0,0,1,0,2.31Z" />
                            <path d="M24.23,20.77H8.08a1.16,1.16,0,1,1,0-2.31H24.23a1.16,1.16,0,0,1,0,2.31Z" />
                            <path d="M19.62,30H12.69a1.16,1.16,0,0,1,0-2.31h6.93a1.16,1.16,0,0,1,0,2.31Z" />
                            <path d="M31.15,2.31h-30A1.16,1.16,0,0,1,1.15,0h30a1.16,1.16,0,1,1,0,2.31Z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-table-container table-responsive mt-4">
            {data.length !== 0 && (
              <table className="dashboard-table">
                <thead>
                  <tr>
                    <th>Agent Name</th>
                    <th>Mobile No.</th>
                    <th>Email ID</th>
                    <th>RERA No.</th>
                    <th>KYC Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={item._id}>
                        <td>{`${item.first_name.charAt(0).toUpperCase() +
                          item.first_name.slice(1)} ${item.last_name
                          .charAt(0)
                          .toUpperCase() + item.last_name.slice(1)}`}</td>
                        <td>{item.mobile}</td>
                        <td>{item.email}</td>
                        <td>{state.reraLicenseNumber}</td>
                        <td>{item.kyc_status}</td>
                        <td>
                          <button className="btn btn-blue btn-sm btn-rounded-sm mb-0">
                            Remove
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <PaginationCustom
            handleShowNumberOfRecords={handleShowNumberOfRecords}
            handlePagination={handlePagination}
            activePage={activePage}
            totalItemsCount={totalItemsCount}
            itemCountPerpage={itemCountPerpage}
          />
        </div>
      </div>
    </DashboardComponent>
  );
};
export default connect(null, {getRegisterAgent})(
  IsLoadingHOC(AgentManagment, 'Loading .....'),
);
