import React, {useState, useEffect} from 'react';

export const PropertyCreateDate = ({propertyDate}) => {
  Date.prototype.getMonthName = function() {
    var monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return monthNames[this.getMonth()];
  };

  const [date, setDate] = useState({
    month: '',
    date: '',
    year: '',
  });

  useEffect(() => {
    const fullDate = new Date(propertyDate);
    setDate({
      month: fullDate.getMonthName(),
      date: fullDate.getDate(),
      year: fullDate.getFullYear(),
    });
  }, [propertyDate]);
  return <>{`${date.month} ${date.date}, ${date.year}`}</>;
};
