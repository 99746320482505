import React, {useState, useEffect} from 'react';
import {countryCode} from '../../../../config/constant';
import DashboardComponent from '../Dashboard.component';
import {MySelect, MyTextInput} from '../../../form';
import {Formik, Form} from 'formik';
import schemaPropertyAddAgent from '../../../../validationSchema/addAgent';
import {connect} from 'react-redux';
import {registerAgent} from '../../../../redux/actions';
import IsLoadingHOC from '../../../common/IsLoadingHOC';
import {toast} from 'react-toastify';

const AgentAdd = props => {
  const [profilePic, setProfilePic] = useState('');
  const [profileError, setProfileError] = useState('');
  const {setLoading, registerAgent} = props;
  return (
    <DashboardComponent>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          mobile: '',
          brn_number: '',
          country_code: '',
          image: [],
        }}
        validationSchema={schemaPropertyAddAgent}
        onSubmit={values => {
          setLoading(true);
          var data = new FormData();
          if (values.image.name === undefined || values.image.name === '') {
            setProfileError('No Profile pic Image Selected');
          } else {
            setProfileError('');
            data.append('image', values.image);
          }
          data.append('first_name', values.first_name);
          data.append('last_name', values.last_name);
          data.append('mobile', values.mobile);
          data.append('email', values.email);
          data.append('country_code', values.country_code);
          data.append('brn_number', values.brn_number);
          console.log('values', data);
          registerAgent(data)
            .then(
              res => {
                setLoading(false);
                toast.success(res.message);
              },
              err => {
                setLoading(false);
                toast.success(err.message);
              },
            )
            .catch(error => toast.success(error.message));
        }}>
        {({setFieldValue, values}) => (
          <Form>
            <div className="tab-pane" id="company-nav-tab-add-agent">
              <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
                <label className="">
                  <div className="image-upload">
                    <input
                      name="image"
                      type="file"
                      className="custom-file-input d-none cursor"
                      onChange={e => {
                        setProfilePic(URL.createObjectURL(e.target.files[0]));
                        setFieldValue('image', e.target.files[0]);
                      }}
                    />
                    <div className="agent-profile_upload">
                      <img
                        alt="Profle pic"
                        className="upload-photo-icon mx-auto cursor"
                        src={
                          profilePic ? profilePic : '/images/upload-photo.svg'
                        }
                      />
                    </div>
                    {profileError.length > 0 && (
                      <div className="error">
                        {values.image.name === undefined ? profileError : ''}
                      </div>
                    )}
                    <p class="my-3 font-weight-bold">
                      Upload your Agent Profile
                    </p>
                  </div>
                </label>

                <div className="row my-4">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="agent-first-name">First Name</label>
                      <MyTextInput
                        type="text"
                        name="first_name"
                        className="form-control secondary-input"
                        id="agent-first-name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="agent-last-name">Last Name</label>
                      <MyTextInput
                        type="text"
                        name="last_name"
                        className="form-control secondary-input"
                        id="agent-last-name"
                      />
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-6 col-12">
                    <label>Mobile Number</label>
                    <div className="form-group form-inline forerroemsg">
                      <MySelect
                        name="country_code"
                        label="CC"
                        id="agent-mobile-no-prepend"
                        className="form-control secondary-select mr-lg-2">
                        <option value="CC"></option>
                        {countryCode.map(item => {
                          return (
                            <option key={item.dial_code} value={item.dial_code}>
                              {item.dial_code}
                            </option>
                          );
                        })}
                      </MySelect>
                      <MyTextInput
                        type="text"
                        className="form-control secondary-input"
                        id="agent-mobile-no"
                        name="mobile"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="agent-email-id">Email ID</label>
                      <MyTextInput
                        type="email"
                        className="form-control secondary-input"
                        id="agent-email-id"
                        name="email"
                      />
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-6 col-12">
                    <label htmlFor="agent-brn-number">BRN Number</label>
                    <MyTextInput
                      type="text"
                      className="form-control secondary-input"
                      id="agent-brn-number"
                      name="brn_number"
                    />
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-3 col-md-6 col-12">
                    <button
                      className="btn btn-gradient-secondary w-100"
                      type="submit">
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </DashboardComponent>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    registerAgents: () => dispatch(registerAgent()),
  };
};

export default connect(mapDispatchToProps, {registerAgent})(
  IsLoadingHOC(AgentAdd, 'Loading .....'),
);
