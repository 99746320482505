import React from 'react';
import {useSelector} from 'react-redux';
import {Greeting} from './Greeting';

export default () => {
  const username = useSelector(state => {
    let name = state.app.user.first_name;
    return name[0].charAt(0).toUpperCase() + name.slice(1);
  });

  return (
    <div className="row header-bottom no-gutters">
      <div className="col-lg-12 col-md-12 col-12 text-center">
        <div className="header-bottom-text p-3">
          <h3 className="text-white mb-0">
            “Hello” {username}, Welcome to Dashboard
          </h3>
        </div>
      </div>
    </div>
  );
};
