import React from 'react';
import {Link} from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="pageNotFound">
      <img src={require('../../assets/images/404-banner1.png')} alt = "404 Error"/>
      <h1 className="font-weight-bold text-dark">PAGE NOT FOUND</h1>
      <p>
        The page you are looking for was moved renamed or might never existed
      </p>
      <div class="btnBox">
        <Link to="/" class="btn btn-gradient">
          Visit Home
        </Link>
        <a href="mailto:cs@aqarchain.com" class="btn btn-gradient-secondary">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
