import React, {useState} from 'react';
import {connect} from 'react-redux';
import {getInTouch} from '../../../redux/actions';
import IsLoadingHOC from '../../common/IsLoadingHOC';
import {ToastContainer, toast} from 'react-toastify';
var Recaptcha = require('react-recaptcha');
class FooterHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      contactNumber: '',
      interestedIn: '',
      message: '',
      isVerified: false,
    };
  }
  validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  jsonToURI = json => {
    return encodeURIComponent(JSON.stringify(json));
  };
  // if (this.state.isVerified === false) {
  //   alert('fsdf');
  //   return false;
  // }
  onSubmit = () => {
    const {name, email, contactNumber, interestedIn, message} = this.state;

    if (name === '') {
      alert('name should not be empty');
      return false;
    } else if (email === '') {
      alert('email should not be empty');
      return false;
    } else if (contactNumber === '') {
      alert('contactNumber should not be empty');
      return false;
    } else if (interestedIn === '') {
      alert('Interest should  be selected');
      return false;
    } else if (message === '') {
      alert('message should not be empty');
      return false;
    }
    if (!this.validateEmail(email)) {
      alert('Email should be correct formate');
      return false;
    }
    const {setLoading} = this.props;
    setLoading(true);
    this.props
      .getInTouch(this.state)
      .then(response => {
        this.setState({
          data: response.data,
          name: '',
          email: '',
          contactNumber: '',
          interestedIn: '',
          message: '',
        });
        setLoading(false);
        toast.success(response.message);
      })
      .catch(error => {
        console.log('error', error);
        setLoading(false);
        toast.error(error);
      });
  };

  submitHandle() {
    if (this.state.isVerified === false) {
      alert('Please verify that you are human!');
    } else {
      this.onSubmit();
    }
  }

  render() {
    const {name, email, contactNumber, interestedIn, message} = this.state;
    const callback = () => {};
    console.log('this.state.isVerified', this.state.isVerified);
    return (
      <footer className="footer">
        <ToastContainer />
        <div id="footer-form-container" className="container-lg">
          <div className="row no-gutters">
            <div className="col-lg-11">
              <div id="footer-form">
                <div className="row">
                  <div className="col-lg-4 text-center text-lg-left">
                    <h1 className="mb-5">Let’s Connect </h1>
                    <hr className="height-4 bg-gradient no-border mb-5 w-50 mx-auto mx-md-0" />
                    <p className="mb-5 text-justify">
                      Looking for any kind of help? Our customer support ensures
                      that you're receiving the best information possible. From
                      basic to highly complicated, we'd love to help you!
                    </p>
                    <h3 className="mb-0">
                      <a href="mailto:cs@aqarchain.com">
                        <span className="text-dark font-weight-bold">
                          cs@aqarchain.com
                        </span>
                      </a>
                    </h3>
                    <hr className="height-2 bg-gradient no-border mb-5 w-50 mx-auto mx-md-0" />
                  </div>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            value={name}
                            onChange={e =>
                              this.setState({name: e.target.value})
                            }
                            className="form-control"
                            placeholder="Your Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            value={email}
                            onChange={e =>
                              this.setState({email: e.target.value})
                            }
                            className="form-control"
                            placeholder="Your Email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            value={contactNumber}
                            onChange={e =>
                              this.setState({contactNumber: e.target.value})
                            }
                            className="form-control"
                            placeholder="Contact Number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-md-12">
                        <span className="mr-4">Interested In: </span>
                        <div className="form-check form-check-inline mr-3">
                          <input
                            name="interested"
                            checked={interestedIn === 'BUY'}
                            onChange={e => this.setState({interestedIn: 'BUY'})}
                            className="form-check-input"
                            type="radio"
                            id="interested_in_buy"
                            value="BUY"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="interested_in_buy">
                            Buy
                          </label>
                        </div>
                        <div className="form-check form-check-inline mr-3">
                          <input
                            name="interested"
                            checked={interestedIn === 'RENT'}
                            onChange={e =>
                              this.setState({interestedIn: 'RENT'})
                            }
                            className="form-check-input"
                            type="radio"
                            id="interested_in_rent"
                            value="RENT"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="interested_in_rent">
                            Rent
                          </label>
                        </div>
                        <div className="form-check form-check-inline mt-md-0 mt-3">
                          <input
                            checked={interestedIn === 'FRACTIONAL'}
                            onChange={e =>
                              this.setState({interestedIn: 'FRACTIONAL'})
                            }
                            name="interested"
                            className="form-check-input"
                            type="radio"
                            id="interested_in_fractional_property"
                            value="FRACTIONAL"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="interested_in_fractional_property">
                            Fractional Property
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            value={message}
                            onChange={e =>
                              this.setState({message: e.target.value})
                            }
                            className="form-control"
                            rows="5"
                            placeholder="Message"></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <Recaptcha
                          sitekey="6LebiT4bAAAAAInXS0cGZePWWxVO5c4Zr6lmEeYO"
                          render="explicit"
                          onloadCallback={callback}
                          verifyCallback={() => {
                            this.setState({isVerified: true});
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          onClick={() => {
                            this.submitHandle();
                          }}
                          className="form-submit btn btn-lg btn-gradient-secondary">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1">
              <div className="social-icons d-flex justify-content-around align-self-stretch h-100 d-inline-block">
                <div className="social-icon" title="Whatsapp">
                  <a
                    href="https://api.whatsapp.com/send?phone=+1 (631) 900-2727"
                    target="_blank"
                    title="Whatsapp">
                    <img
                      src={require('../../../assets/images/icon-social_media-whatsapp.svg')}
                      alt="Whatsapp"
                    />
                  </a>
                </div>
                <div className="social-icon" title="Linkedin">
                  <a
                    href="#abc"
                    href={'https://www.linkedin.com/company/18961648/'}
                    target="_blank"
                    title="Linkedin">
                    <img
                      src={require('../../../assets/images/icon-social_media-linkedin.svg')}
                      alt="Linkedin"
                    />
                  </a>
                </div>
                <div className="social-icon" title="Twitter">
                  <a
                    href="#abc"
                    href={'https://twitter.com/aqarchain_io'}
                    target="_blank"
                    title="Twitter">
                    <img
                      src={require('../../../assets/images/icon-social_media-twitter.svg')}
                      alt="Twitter"
                    />
                  </a>
                </div>
                <div className="social-icon" title="Instagram">
                  <a
                    href="#abc"
                    href={'https://www.instagram.com/aqarchain/?hl=en'}
                    target="_blank"
                    title="Instagram">
                    <img
                      src={require('../../../assets/images/icon-social_media-instagram.svg')}
                      alt="Instagram"
                    />
                  </a>
                </div>
                <div className="social-icon" title="Facebook">
                  <a
                    href="#abc"
                    href={'https://www.facebook.com/Aqar-Chain-105367495055665'}
                    target="_blank"
                    title="Facebook">
                    <img
                      src={require('../../../assets/images/icon-social_media-facebook.svg')}
                      alt="Facebook"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default connect(null, {getInTouch})(IsLoadingHOC(FooterHome, 'Loading'));
