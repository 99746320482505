import * as Yup from 'yup';
const schemaPropertyAdd = Yup.object({
  property_for: Yup.string().required('Please select Property type'),
  title: Yup.string()
    .min(5, 'Must be greater then 5')
    .required('Required'),
  property_type: Yup.string().required('Required'),
  currency: Yup.string().required('Required'),
  price: Yup.number().required('Required'),
  country: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  address2: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  // propPics: Yup.mixed().required('Picture of property'),
  // propDocs: Yup.mixed().required('Document of property'),
  //   propPics: Yup.array().min(1, 'Picture of property'),
  //   propDocs: Yup.array().min(1, 'Required'),
  // cover:Yup
  // .mixed()
  // .required("You need to provide a file")
  // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc", (value) => {
  //     return value && (
  //         value[0].type === "image/jpeg" ||
  //         value[0].type === "image/bmp" ||
  //         value[0].type === "image/png" ||
  //         value[0].type === 'application/pdf' ||
  //         value[0].type === "application/msword"
  //     );
  // }),
  // floorPlans:,
  // video360: Yup.mixed().required('fileSize', 'The file is too large', value => {
  //   // if (!value.length) return true; // video360 is optional
  //   return value[0].size <= 2000000;
  // }),
  propertyFeature: Yup.object({
    number_of_beds: Yup.number().required('Required'),
    number_of_baths: Yup.number().required('Required'),
    propertyArea: Yup.number().required('Required'),
    areaType: Yup.string().required('Required'),
  }),
  amenities: Yup.array().min(1, 'Required'),
  // video: Yup.object().required("Required"),
  terms_acceptance: Yup.boolean().oneOf(
    [true],
    'Please select term and conditions',
  ),
});

export default schemaPropertyAdd;
