import React from 'react';

export default () => {
  return (
    <div id="property-detail-banner" className="container-lg">
      <div className="row">
        <div className="col-lg-8 text-center">
          <div className="mt-5 mb-5">
            <a href="#abc">
              <img
                className="img-fluid"
                src="/images/property-detail-banner.png"
                alt="Best Deals in Dubai"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
