import React from "react";
import Select from "react-select";
const State = ({data, onChange, selected}) => {

	return (
		<Select className="select-box" 
			value={selected}
			options={data}
			placeholder="Select State"
			onChange={(e) => onChange(e)}
		/>
	);
};

export default State;