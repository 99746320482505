import React from 'react';
import {defaultOptions} from '../../../config';
import {PropertyCreateDate} from '../../common/PropertyCreateDate';

export default ({data}) => {
  const {property_review_data} = data;
  return (
    <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
      <h2 className="font-weight-bold mb-4">Reviews</h2>
      <div id="property-detail-reviews">
        {data.hasOwnProperty('property_review_data')
          ? data.property_review_data[0]?.ratings.map((item, index) => {
              return (
                <div className="property-detail-review d-block d-lg-flex pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                  <div className="property-detail-review-image">
                    <img
                      alt=""
                      src={`${defaultOptions.baseUrl}${item.profile}`}
                    />
                  </div>
                  <div className="property-detail-review-text mt-3 mt-lg-0 ml-lg-3">
                    <h3 className="font-weight-bold mb-0">
                      {item.first_name} {item.last_name}
                    </h3>
                    <small className="text-grey font-weight-medium">
                      <>
                        Reviewed On{' '}
                        <PropertyCreateDate
                          propertyDate={item.descriptions[0].date}
                        />
                      </>
                      {/* <PropertyCreateDate propertyDate={item.date} /> */}
                    </small>
                    <div className="d-flex property-rating my-2">
                      <div className="cursor-pointer">
                        <img
                          src={require(`../../../assets/images/icon-star${
                            item.stars >= 1 ? '-active' : ''
                          }.svg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/images/icon-star${
                            item.stars >= 2 ? '-active' : ''
                          }.svg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/images/icon-star${
                            item.stars >= 3 ? '-active' : ''
                          }.svg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/images/icon-star${
                            item.stars >= 4 ? '-active' : ''
                          }.svg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/images/icon-star${
                            item.stars >= 5 ? '-active' : ''
                          }.svg`)}
                          alt=""
                        />
                      </div>
                    </div>
                    {item.descriptions.map((i, k) => {
                      return <p className="mb-0">{i.description}</p>;
                    })}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};
