import React from "react";
import {countryCode} from "../../config/constant";
const Country = ({onChange, selected}) => {
	return (
		<select 
			onChange={(e) => onChange(e)}
			className="form-control secondary-select" id="cc">
			<option>CC</option>
			{
				countryCode.map((item, index) => {
					return (<option key={index} value={item.dial_code}>
						{`${item.name} (${item.dial_code})`}
					</option>);
				})
			}
		</select>
	);
};

export default Country;