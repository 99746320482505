import * as Yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schemaPropertyAddAgent = Yup.object({
  first_name: Yup.string().required('First Name is Required'),
  last_name: Yup.string().required('Last Name is Required'),
  email: Yup.string().required('Please enter the required field'),

  mobile: Yup.string()
    .required('Mobile Number is Required')
    .matches(phoneRegExp, 'Mobile number is not valid')
    .min(9, 'Please enter valid number')
    .max(10, 'Please enter valid number'),
  //   mobile: Yup.number()
  //     .required('Required')
  //     .min(7),
  brn_number: Yup.number().required('BRN Number is Required'),
});

export default schemaPropertyAddAgent;
