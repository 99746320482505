import React from 'react';
import DashboardComponent from '../Dashboard.component';
const Tokenization = () => {
  return (
    <DashboardComponent>
      <div
        className="tab-pane fade active show"
        id="user-dashboard-nav-tab-tokenization-step-1"
        role="tabpanel"
        aria-labelledby="user-dashboard-nav-tab-tokenization-tab-1">
        <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow has-border-radius">
          <div className="row d-lg-flex align-items-center">
            <div className="col-lg-12 col-12 text-lg-left text-center">
              <h3 className="mb-3 mb-lg-4">Start Tokenization</h3>
            </div>
            <div className="col-lg-4 col-12 my-lg-0 my-2">
              <button className="btn btn-gradient-secondary w-100 has-border-radius">
                Add new Property{' '}
              </button>
            </div>
            <div className="col-lg-6 col-12 my-lg-0 my-2">
              <select className="form-control secondary-select">
                <option>Select from Listed Properties</option>
                <option>Listed Property #1</option>
                <option>Listed Property #2</option>
                <option>Listed Property #3</option>
                <option>Listed Property #4</option>
                <option>Listed Property #5</option>
                <option>Listed Property #6</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </DashboardComponent>
  );
};
export default Tokenization;
