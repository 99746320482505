import React from 'react';

export default () => {
  return (
    <div className="text-center text-lg-left mt-4 p-4 p-lg-5 has-box-shadow">
      <h3 className="mb-4">
        You may also <span className="font-weight-bold">Like</span>
      </h3>
      <div
        className="owl-carousel owl-loaded owl-drag"
        id="other-property-carousel">
        <div className="owl-stage-outer">
          <div
            className="owl-stage"
            style={{
              transform: 'translate3d(0px, 0px, 0px)',
              transition: 'all 0s ease 0s',
              width: '1297px',
            }}>
            <div
              className="owl-item active"
              style={{width: '402.328px', marginRight: '30px'}}>
              <div className="item">
                <div className="other-property">
                  <div className="other-property-images">
                    <a href="abc">
                      <img
                        src="http://placehold.jp/40/1f282f/f0e25c/400x600.png?text=Other%20Property"
                        alt=""
                      />
                    </a>
                    <span className="other-property-banner">
                      <span className="mr-3">50%</span>
                      <span className="text-white">Sale</span>
                    </span>
                  </div>
                  <div className="card property-card other-property-card has-box-shadow">
                    <div className="card-body">
                      <div className="property-id">
                        <p className="mb-1">
                          Property ID:{' '}
                          <span className="font-weight-bold">AQ96034</span>
                        </p>
                      </div>
                      <div className="property-meta">
                        <p className="d-flex justify-content-start">
                          <span className="mr-3">Villa</span>
                          <span className="mr-auto font-weight-bold">
                            AED 8000
                          </span>
                        </p>
                      </div>
                      <div className="property-agent">
                        <img
                          className="property-agent-image"
                          src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                          alt=""
                        />
                        <span className="property-agent-name">
                          Agent:{' '}
                          <span className="font-weight-bold">Miyadh Wahid</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="owl-item"
              style={{width: '402.328px', marginRight: '30px'}}>
              <div className="item">
                <div className="other-property">
                  <div className="other-property-images">
                    <a href="abc">
                      <img
                        src="http://placehold.jp/40/1f282f/f0e25c/400x600.png?text=Other%20Property"
                        alt=""
                      />
                    </a>
                    <span className="other-property-banner">
                      <span className="mr-3">50%</span>
                      <span className="text-white">Sale</span>
                    </span>
                  </div>
                  <div className="card property-card other-property-card has-box-shadow">
                    <div className="card-body">
                      <div className="property-id">
                        <p className="mb-1">
                          Property ID:{' '}
                          <span className="font-weight-bold">AQ96034</span>
                        </p>
                      </div>
                      <div className="property-meta">
                        <p className="d-flex justify-content-start">
                          <span className="mr-3">Villa</span>
                          <span className="mr-auto font-weight-bold">
                            AED 8000
                          </span>
                        </p>
                      </div>
                      <div className="property-agent">
                        <img
                          className="property-agent-image"
                          src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                          alt=""
                        />
                        <span className="property-agent-name">
                          Agent:{' '}
                          <span className="font-weight-bold">Miyadh Wahid</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="owl-item"
              style={{width: '402.328px', marginRight: '30px'}}>
              <div className="item">
                <div className="other-property">
                  <div className="other-property-images">
                    <a href="abc">
                      <img
                        src="http://placehold.jp/40/1f282f/f0e25c/400x600.png?text=Other%20Property"
                        alt=""
                      />
                    </a>
                    <span className="other-property-banner">
                      <span className="mr-3">50%</span>
                      <span className="text-white">Sale</span>
                    </span>
                  </div>
                  <div className="card property-card other-property-card has-box-shadow">
                    <div className="card-body">
                      <div className="property-id">
                        <p className="mb-1">
                          Property ID:{' '}
                          <span className="font-weight-bold">AQ96034</span>
                        </p>
                      </div>
                      <div className="property-meta">
                        <p className="d-flex justify-content-start">
                          <span className="mr-3">Villa</span>
                          <span className="mr-auto font-weight-bold">
                            AED 8000
                          </span>
                        </p>
                      </div>
                      <div className="property-agent">
                        <img
                          className="property-agent-image"
                          src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                          alt=""
                        />
                        <span className="property-agent-name">
                          Agent:{' '}
                          <span className="font-weight-bold">Miyadh Wahid</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="owl-nav disabled">
          <button type="button" role="presentation" className="owl-prev">
            <span aria-label="Previous">‹</span>
          </button>
          <button type="button" role="presentation" className="owl-next">
            <span aria-label="Next">›</span>
          </button>
        </div>
        <div className="owl-dots">
          <button role="button" className="owl-dot active">
            <span></span>
          </button>
          <button role="button" className="owl-dot">
            <span></span>
          </button>
          <button role="button" className="owl-dot">
            <span></span>
          </button>
        </div>
      </div>
      <div className="text-center">
        <button className="btn btn-gradient-secondary mt-4">View All</button>
      </div>
    </div>
  );
};
