import React from 'react';
import {useLocation, Link} from 'react-router-dom';

const OwnerMenu = () => {
  let location = useLocation();
  const CustomLink = ({url, className, id, title}) => {
    return (
      <li className="nav-item">
        <Link to={url} className={className} id={id}>
          {title}
        </Link>
      </li>
    );
  };
  return (
    <>
      {/* <CustomLink
        url="/dashboard"
        className={`nav-link ${
          location.pathname === '/dashboard' ? 'active' : ''
        }`}
        id="company-nav-tab-dashboard-tab"
        title="Dashboard"
      /> */}
      <CustomLink
        url="/dashboard/profile"
        className={`nav-link ${
          location.pathname === '/dashboard/profile' ? 'active' : ''
        }`}
        id="company-nav-tab-my-profile-tab"
        title="My Profile"
      />
      <CustomLink
        url="/dashboard/kyc"
        className={`nav-link ${
          location.pathname === '/dashboard/kyc' ? 'active' : ''
        }`}
        id="user-dashboard-nav-tab-kyc-tab"
        title="KYC"
      />
      <CustomLink
        url="/dashboard/save-properties"
        className={`nav-link ${
          location.pathname === '/dashboard/save-properties' ? 'active' : ''
        }`}
        id="user-dashboard-nav-tab-my-saved-properties-tab"
        title="My Saved Properties"
      />
      {/* Need To Hide On Live Server */}
      {/* <CustomLink
        url="/dashboard/property-listing"
        className={`nav-link ${
          location.pathname === '/dashboard/property-listing' ? 'active' : ''
        }`}
        id="company-nav-tab-properties-uploaded-tab"
        title="My Listed Properties"
      />
      <CustomLink
        url="/dashboard/enquery"
        className={`nav-link ${
          location.pathname === '/dashboard/enquery' ? 'active' : ''
        }`}
        id="company-nav-tab-enquires-tab"
        title="Enquires"
      />
      <CustomLink
        url="/dashboard/tokenization"
        className={`nav-link ${
          location.pathname === '/dashboard/tokenization' ? 'active' : ''
        }`}
        id="user-dashboard-nav-tab-tokenization-tab-1"
        title="Tokenization"
      />
      <CustomLink
        url="/dashboard/portfolio"
        className={`nav-link ${
          location.pathname === '/dashboard/portfolio' ? 'active' : ''
        }`}
        id="user-dashboard-nav-tab-my-portfolio-tab"
        title="My Portfolio"
      />
      <CustomLink
        url="/dashboard/wallet"
        className={`nav-link ${
          location.pathname === '/dashboard/wallet' ? 'active' : ''
        }`}
        id="user-dashboard-nav-tab-my-wallet-tab"
        title="My Wallet"
      /> */}
    </>
  );
};

export default OwnerMenu;
