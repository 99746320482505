export const USER_DATA_SAVE = 'USER_DATA_SAVE';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';
export const LOADING = 'LOADING';
export const SAVE_CONFIG_DATA = 'SAVE_CONFIG_DATA';
export const LOGOUT = 'LOGOUT';
export const SAVE_LANGUAGE = 'SAVE_LANGUAGE';
export const SAVE_FILTER = 'SAVE_FILTER';
export const SAVE_PRODUCTS = 'SAVE_PRODUCTS';

/*ADMIN USER*/
export const ADD_ADMIN_USER = 'ADD_ADMIN_USER';
export const ADMIN_USER_SAVE = 'ADMIN_USER_SAVE';

export const PROPERTYLIST = 'PROPERTYLIST';
export const SAVE_VARIFY_TOKEN = 'SAVE_VARIFY_TOKEN';
export const REMOVE_VARIFY_TOKEN = 'REMOVE_VARIFY_TOKEN';

export const ADD_NEW_PROPERTY = 'ADD_NEW_PROPERTY';

export const USER_WISHLISED_PROPERTY = 'USER_WISHLISED_PROPERTY';
export const USER_UPLOADED_PROPERTY = 'USER_UPLOADED_PROPERTY';
