import {USER_DATA_SAVE} from './types';
import {aqarChain} from '../../aqarchain';

export const addReview = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    console.log(data);
    const response = await aqarChain.post('/api/rating/add/review', data);
    console.log('data', response);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};

export const likeProperty = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/property/like/property', data);
    console.log('property like response', response);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response);
    }
  });
};

export const removeLikeProperty = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/property/remove/like', data);
    console.log('remove like ', response);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};

export const addToWishList = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/wishlist/add/property', data);
    console.log('add to wishlist', response);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response);
    }
  });
};

export const removeFromWhisList = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post(
      '/api/wishlist/remove/property',
      data,
    );
    console.log('remove form wishlist', response);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response.response);
    }
  });
};
