import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import './assets/css/theme.css';
import './assets/css/custom.css';
import './assets/css/dashboard.css';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import 'reactjs-popup/dist/index.css';
import '../node_modules/video-react/dist/video-react.css';

import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_ar from './translations/ar/common.json';
import common_en from './translations/en/common.json';
import {connect} from 'react-redux';
import routes from './routing/Routing';
import jwt from 'jsonwebtoken';
import cron from 'node-schedule';
// import landingRouting from "./routing/LandingRouting";
import {ToastContainer} from 'react-toastify';
//import {store} from "./redux/store";
// let language = store.getState("app").app.language;
// console.log('persistor', store.getState("app"));
import {varifyWebAccessToken, getConfigData} from './redux/actions';
import {logOut} from './redux/actions';

class App extends React.Component {
  state = {
    varifyToken: null,
  };

  constructor(props) {
    super(props);
    if (props.token) {
      let {exp} = jwt.decode(props.token, {complete: false});
      if (new Date(exp * 1000) <= new Date()) {
        this.props.logOut();
      } else {
        cron.scheduleJob(new Date(exp * 1000), () => {
          this.props.logOut();
        });
      }
    }
  }

  state = {
    varifyToken: null,
  };

  componentDidMount() {
    // alert(this.props.webaccessToken)
    // //  localStorage.setItem('access_token', this.props.webaccessToken)
    // this.props.varifyWebAccessToken({
    // 	token:  this.props.webaccessToken
    // }).then(response => {
    // 	alert("trueueueueueue")
    // 	this.setState({varifyToken: true});
    // }).catch(error => {
    // 	console.log('error', error);
    // 	this.setState({varifyToken: true});
    // });
    // if(this.props.webaccessToken===undefined){
    if (
      localStorage.getItem('access_token') &&
      this.props.webaccessToken !== undefined &&
      this.props.webaccessToken !== null
    ) {
      this.setState({varifyToken: true});
    } else {
      this.setState({varifyToken: false});
    }
    // }

    this.props
      .getConfigData()
      .then(response => {
        // console.log("resonse ", response);
        this.setState({config: response.data});
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  render() {
    const {varifyToken} = this.state;
    i18next.init({
      interpolation: {escapeValue: false}, // React already does escaping
      lng: this.props.language, // language to use
      resources: {
        en: {
          common: common_en, // 'common' is our custom namespace
        },
        ar: {
          common: common_ar,
        },
      },
    });
    return (
      <I18nextProvider i18n={i18next}>
        <Router>
          {/* <Switch>{varifyToken ? routes: varifyToken === null ? null : landingRouting}</Switch> */}
          <Switch>{routes}</Switch>
        </Router>
        <ToastContainer />
      </I18nextProvider>
    );
  }
}
const mapStateToProps = ({app}) => {
  const {language, webaccessToken, token} = app;
  return {
    language,
    webaccessToken,
    token,
  };
};
export default connect(mapStateToProps, {
  varifyWebAccessToken,
  getConfigData,
  logOut,
})(App);
