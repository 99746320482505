import React from 'react';
import {connect} from 'react-redux';
import {
  getPropertyDetails,
  likeProperty,
  addToWishList,
  // addQuery,
} from '../../../redux/actions';
import View360 from '../../common/360View';
import VideoPlayer from '../../common/VideoPlayer';
import PropertySlider from './PropertySlider';
import Popup from 'reactjs-popup';
import Price from '../../common/ProjectComponent/Price';
import Deals from './Deals';
import IsLoadingHOC from '../../common/IsLoadingHOC';
import ReviewList from './ReviewList';
import ContactCart from './ContactCart';
import Suggestion from './Suggestion';
import Amenities from './Amenities';
import {toast} from 'react-toastify';
import GoogleMapReact from 'google-map-react';
import {defaultOptions} from '../../../config';
// import {Marker} from '../../common/map';
import PropertyLike from '../../common/PropertyLike';
import PropertyWishList from '../../common/PropertyWishList';
class PropertyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      whatup: false,
      email: false,
      likeCount: 0,
      wishlisted: false,
    };
  }
  componentDidMount() {
    this.props
      .getPropertyDetails(this.props.match.params.id)
      .then(response => {
        this.setState({data: response.data});
        this.setState({likeCount: response.data.likes});
        this.setState({wishlisted: response.data.wishlisted});
      })
      .catch(error => {
        console.log('Get Property Details Error', error);
      });
  }
  handleLikeClick = prop_id => {
    const {setLoading} = this.props;
    setLoading(true);
    this.props
      .likeProperty({prop_id: prop_id})
      .then(
        response => {
          setLoading(false);
          this.setState({
            likeCount: this.state.likeCount + 1,
          });
          toast.success(response.message);
        },
        err => {
          this.setState({
            likeCount: this.state.likeCount,
          });
          setLoading(false);
          toast.success(err.message);
        },
      )
      .catch(error => {
        setLoading(false);
        toast.error(error);
      });
  };
  handleWishListClick = property_id => {
    const {setLoading} = this.props;
    setLoading(true);
    this.props
      .addToWishList({property_id: property_id})
      .then(
        response => {
          setLoading(false);
          toast.success(response.message);
          this.setState({wishlisted: true});
        },
        error => {
          toast.success(error.message);
          setLoading(false);
        },
      )
      .catch(error => {
        setLoading(false);
        toast.error(error);
      });
  };
  render() {
    const {data} = this.state;
    // const {token} = this.props;
    return (
      <>
        {/* <View360 /> */}
        <section id="property-detail-header">
          <div className="container-lg">
            <div className="row">
              <div className="col-lg-8 text-center text-lg-left">
                <p className="mt-3 mb-0">
                  Property ID:{' '}
                  <span className="font-weight-bold">{data.id}</span>
                </p>
                <div className="d-flex justify-content-between">
                  <h1 className="font-weight-bold mb-3">{data.title}</h1>
                  <div
                    className="d-flex justify-content-between"
                    style={{alignItems: 'center', paddingRight: '1rem'}}>
                    <PropertyLike item={data} />
                    <PropertyWishList item={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PropertySlider data={data} />
        <section id="property-detail-meta-and-banner">
          <div id="property-detail-meta" className="container-lg">
            <div className="row">
              <div className="col-lg-8">
                <div id="property-detail-meta-top">
                  <div className="text-center d-block d-lg-flex justify-content-center">
                    <Popup
                      trigger={
                        <div
                          id="property-detail-meta-360_tour"
                          className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                          <img
                            alt=""
                            src="/images/icon-property-detail-meta-360_tour.svg"
                          />
                          <span className="ml-3">360° tour</span>
                        </div>
                      }
                      modal>
                      <View360 />
                    </Popup>
                    <Popup
                      trigger={
                        <div
                          id="property-detail-meta-video"
                          className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                          <img
                            alt=""
                            src="/images/icon-property-detail-meta-video.svg"
                          />
                          <span className="ml-3">Video</span>
                        </div>
                      }
                      modal>
                      <VideoPlayer data={data} />
                    </Popup>
                    <div
                      id="property-detail-meta-report"
                      className="d-block d-lg-flex property-detail-meta align-items-center">
                      <img
                        alt=""
                        src="/images/icon-property-detail-meta-report.svg"
                      />
                      <span className="ml-3">Report</span>
                    </div>
                  </div>
                </div>
                <div id="property-detail-meta-middle">
                  <div className="text-center d-block text-lg-left d-lg-flex justify-content-center">
                    <div
                      id="property-detail-meta-price"
                      className="property-detail-meta pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-5 mr-lg-5">
                      <h2 className="mb-0">Price</h2>
                      <h1 className="mb-0 font-weight-bold">
                        <Price price={data.price} currency={data.currency} />
                      </h1>
                    </div>
                    <div
                      id="property-detail-meta-location"
                      className="property-detail-meta pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-5 mr-lg-5">
                      <h2 className="mb-0">Location</h2>
                      <h1 className="mb-0 font-weight-bold">
                        {data.district} {data.city}
                      </h1>
                    </div>
                    <div
                      id="property-detail-meta-type"
                      className="property-detail-meta">
                      <h2 className="mb-0">Type</h2>
                      {data.hasOwnProperty('property_type') ? (
                        <h1 className="mb-0 font-weight-bold">
                          {data.property_type[0].type_title}
                        </h1>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div id="property-detail-meta-bottom">
                  {data?.hasOwnProperty('propertyFeature') ? (
                    <div className="text-center d-block d-lg-flex justify-content-center align-items-center">
                      <div
                        id="property-detail-meta-bedrooms"
                        className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                        <img
                          alt=""
                          src="/images/icon-icon-property-detail-meta-bedrooms.svg"
                        />
                        <span className="ml-3 font-weight-bold">
                          {data.propertyFeature.number_of_beds} Bed
                        </span>
                      </div>
                      <div
                        id="property-detail-meta-bathrooms"
                        className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                        <img
                          alt=""
                          src="/images/icon-property-detail-meta-bathrooms.svg"
                        />
                        <span className="ml-3 font-weight-bold">
                          {data.propertyFeature.number_of_baths} Bath
                        </span>
                      </div>
                      <div
                        id="property-detail-meta-area"
                        className="d-block d-lg-flex property-detail-meta align-items-center">
                        <img
                          alt=""
                          src="/images/icon-property-detail-meta-area.svg"
                        />
                        <span className="ml-3 font-weight-bold">
                          {data.propertyFeature.propertyArea}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Deals />
          <div id="property-detail-tab-links" className="container-lg">
            <div className="row">
              <div className="col-lg-8">
                <ul
                  className="nav nav-tabs nav-tabs nav-justified md-tabs property-detail-nav"
                  id="property-detail-tabs">
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link text-nowrap"
                      id="property-detail-tab-listed-by-tab"
                      href="#property-detail-tab-listed-by">
                      Listed By
                    </a>
                  </li>
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link"
                      id="property-detail-tab-description-tab"
                      href="#property-detail-tab-description">
                      Description
                    </a>
                  </li>
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link active"
                      id="property-detail-tab-amenities-tab"
                      href="#property-detail-tab-amenities">
                      Amenities
                    </a>
                  </li>
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link"
                      id="property-detail-tab-location-tab"
                      href="#property-detail-tab-location">
                      Location
                    </a>
                  </li>
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link"
                      id="property-detail-tab-reviews-tab"
                      href="#property-detail-tab-reviews">
                      Reviews
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="property-detail-content">
          <div className="container-lg">
            <div className="row">
              <div className="col-lg-8">
                <div
                  className="tab-content mt-3 mb-3"
                  id="property-detail-tabs-content">
                  <div className="my-5" id="property-detail-tab-listed-by">
                    <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
                      <h2 className="font-weight-bold mb-4">Listed By</h2>
                      <p className="text-capitalize">
                        {data?.listedBy && `${data.listedBy}`}
                      </p>
                    </div>
                  </div>
                  <div className="my-5" id="property-detail-tab-description">
                    <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
                      <h2 className="font-weight-bold mb-4">Description</h2>
                      {data.description}
                    </div>
                  </div>
                  <div className="my-5" id="property-detail-tab-amenities">
                    <Amenities data={data} />
                  </div>
                  <div className="my-5" id="property-detail-tab-location">
                    <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
                      <h2 className="font-weight-bold mb-4">Location</h2>
                      <div style={{height: '400px'}}>
                        {data.hasOwnProperty('location') && (
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: defaultOptions.googleGeoLocationKey,
                            }}
                            defaultZoom={12}
                            defaultCenter={{
                              lat: data.location?.coordinates[0],
                              lng: data.location?.coordinates[1],
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map, maps}) =>
                              new maps.Marker({
                                position: {
                                  lat: data.location?.coordinates[0],
                                  lng: data.location?.coordinates[1],
                                },
                                map,
                                title: data.title,
                              })
                            }></GoogleMapReact>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="my-5"
                    id="property-detail-tab-reviews"
                    aria-labelledby="property-detail-tab-reviews-tab">
                    <ReviewList data={data} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-xs-12 col-sm-8 offset-lg-0 offset-md-3 offset-sm-2 text-center">
                <div className="mb-3 mt-lg-3">
                  <ContactCart data={data} />
                  <Suggestion />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
const mapStateToProps = state => {
  const {products, viewType, token} = state.app;
  return {
    products: products,
    viewType: viewType,
    token,
  };
};
export default connect(mapStateToProps, {
  getPropertyDetails,
  likeProperty,
  addToWishList,
})(IsLoadingHOC(PropertyDetails, 'Property Details'));
