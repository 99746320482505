import React, {useState, useEffect} from 'react';
import {defaultOptions} from '../../../config';
import {connect} from 'react-redux';
import IsLoadingHOC from '../../common/IsLoadingHOC';
import {addQuery} from '../../../redux/actions';
import {PropertyCreateDate} from '../../common/PropertyCreateDate';
import {toast} from 'react-toastify';
const ContactCart = ({data, addQuery, setLoading}) => {
  const [whatup, setWhatup] = useState(false);
  const propertyQuery = id => {
    setLoading(true);
    addQuery({property_id: id})
      .then(
        res => {
          setLoading(false);
          toast.success(res.message);
        },
        error => {
          setLoading(false);
          toast.success(error.message);
        },
      )
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <div
      id="property-contact-card"
      className="text-center text-lg-left p-2 has-box-shadow">
      <div className="property-contact-details">
        <div className="property-contact-details-header p-3">
          <div className="row">
            <div className="col-lg-10 col-10">
              <h3>
                Contact <span className="font-weight-bold">Owner / Agent</span>
              </h3>
            </div>
            <div className="col-lg-2 col-2">
              <img
                alt=""
                className="property-detail-share-icon"
                src="/images/icon-property-detail-share.svg"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-center">
              <img
                alt=""
                className="property-agent-image"
                src={`${defaultOptions.baseUrl}/api${data?.belongTo &&
                  data.belongTo[0].profile}`}
              />
            </div>
            <div className="col-lg-5 col-md-6 col-12">
              <div className="d-block">
                {data.hasOwnProperty('belongTo') ? (
                  <p className="agent-name my-2">
                    {data.belongTo[0].role}
                    <br />
                    <span className="font-weight-bold text-capitalize">
                      {data.belongTo[0].first_name} {data.belongTo[0].last_name}
                    </span>{' '}
                  </p>
                ) : null}

                <p className="company-name my-2">
                  Company
                  <br />
                  <span className="font-weight-bold text-capitalize">
                    {data?.company && data.company[0].companyName}
                    <img
                      alt=""
                      className="company-icon"
                      src={`${defaultOptions.baseUrl}/api${data?.company &&
                        data.company[0].profile}`}
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between">
                <p>Total Property Listed</p>
                <p className="font-weight-bold">35</p>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <p>
                  {data.listedBy === 'AGENT' ? (
                    <>
                      {data.belongTo[0].brn_number === undefined ||
                      data.belongTo[0].brn_number === null
                        ? 'RERA'
                        : 'BRN'}
                    </>
                  ) : (
                    'ORN'
                  )}{' '}
                  Number
                </p>
                <p className="font-weight-bold">
                  {data.listedBy === 'AGENT' ? (
                    <>
                      {data.belongTo[0].brn_number === undefined ||
                      data.belongTo[0].brn_number === null
                        ? '#' + data.company[0].reraLicenseNumber
                        : '#' + data.belongTo[0].brn_number}
                    </>
                  ) : (
                    <>
                      {data?.company && '#' + data.company[0].reraLicenseNumber}
                    </>
                  )}
                </p>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <p>Property Upload date</p>
                <p className="font-weight-bold">
                  <PropertyCreateDate propertyDate={data.createdAt} />
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <button className="btn btn-gradient-secondary w-100 mb-3">
              <span>
                <img
                  alt=""
                  className="call-agent-icon"
                  src="/images/icon-property-detail-call-agent.svg"
                />
              </span>
              Call Agent
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-12">
            {!whatup ? (
              <button
                onClick={() => setWhatup(true)}
                className="btn whatsapp-button">
                Whatsapp
              </button>
            ) : null}
            {data.hasOwnProperty('belongTo') ? (
              whatup ? (
                <button className="btn whatsapp-button">
                  {data.belongTo[0].mobile}
                </button>
              ) : null
            ) : null}
          </div>
          <div className="col-lg-6 col-12 mt-lg-0 mt-2">
            <button
              onClick={() => propertyQuery(data._id)}
              className="btn email-button">
              Enquiry
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(null, {addQuery})(
  IsLoadingHOC(ContactCart, 'Loading ...'),
);
