import React from 'react';
import {useField} from 'formik';
const MyTextInput = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name} className="ml-2">
        {label}
      </label>
      <input className="form-control secondary-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
export default MyTextInput;
