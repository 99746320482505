import React, {useState, useEffect} from 'react';
import DashboardComponent from '../Dashboard.component';
import FilterBarBox from '../../../common/FilterBarBox';
import IsLoadingHOC from '../../../common/IsLoadingHOC';
import {connect, useSelector} from 'react-redux';
import {
  getUserProperties,
  deleteSavedProperties,
} from '../../../../redux/actions';
import {toast} from 'react-toastify';
import MapView from '../../../common/ProppertyView/MapView';
import TableView from '../../../common/ProppertyView/TableView';
import {Link} from 'react-router-dom';
import CardView from '../../../common/ProppertyView/CardView';

const PropertyListing = ({
  setLoading,
  getUserProperties,
  deleteSavedProperties,
}) => {
  const state = useSelector(state => state);
  const {viewType, userUploaded} = state.app;
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotolItemsCount] = useState(1);
  const [itemCountPerpage, setItemCountPerPage] = useState(5);

  const getList = () => {
    setLoading(true);
    getUserProperties({
      pageNumber: activePage,
      limit: itemCountPerpage,
      sort: -1,
    })
      .then(response => {
        setLoading(false);
        setTotolItemsCount(response.count);
      })
      .catch(error => {
        console.error('error', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getList();
  }, [activePage, itemCountPerpage]);

  const handlePagination = async activePage => {
    await setActivePage(activePage);
  };
  const handleShowNumberOfRecords = async itemCountPerpage => {
    await setItemCountPerPage(itemCountPerpage);
  };

  const removeProperty = id => {
    setLoading(true);
    if (id !== undefined || id !== null) {
      deleteSavedProperties(id)
        .then(
          res => {
            toast.success(res.message);
            setLoading(false);
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          },
          err => {
            toast.success(err.message);
            setLoading(false);
          },
        )
        .catch(error => {
          console.log('error', error);
          toast.success('Something Went Worrg');
        });
    }
  };

  const dataCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    minHeight: '500px',
    backgroundColor: '#F0F2F7',
  };

  return (
    <DashboardComponent>
      <div
        className="tab-pane"
        id="company-nav-tab-properties-uploaded"
        role="tabpanel"
        aria-labelledby="company-nav-tab-properties-uploaded-tab">
        <div className="text-center has-border-radius text-lg-left p-4 p-lg-5 has-box-shadow">
          <div className="row">
            <div className="col-lg-9 offset-lg-3 col-12">
              <FilterBarBox />
            </div>
          </div>
          {viewType === 'list' && (
            <>
              {userUploaded.length !== 0 ? (
                <TableView
                  propertyData={userUploaded}
                  removeProperty={removeProperty}
                  handleShowNumberOfRecords={handleShowNumberOfRecords}
                  handlePagination={handlePagination}
                  activePage={activePage}
                  totalItemsCount={totalItemsCount}
                  itemCountPerpage={itemCountPerpage}
                />
              ) : (
                <div style={dataCenter}>
                  You don't have any property listed.{' '}
                  <Link
                    to="/dashboard/property-add"
                    className="form-submit btn btn-sm btn-gradient-secondary">
                    Add your property now!
                  </Link>
                </div>
              )}
            </>
          )}
          {viewType === 'card' && (
            <>
              {userUploaded.length !== 0 ? (
                <CardView
                  propertyData={userUploaded}
                  handleShowNumberOfRecords={handleShowNumberOfRecords}
                  handlePagination={handlePagination}
                  activePage={activePage}
                  totalItemsCount={totalItemsCount}
                  itemCountPerpage={itemCountPerpage}
                />
              ) : (
                <div style={dataCenter}>
                  You don't have any property listed.{' '}
                  <Link
                    to="/dashboard/property-add"
                    className="form-submit btn btn-sm btn-gradient-secondary">
                    Add your property now!
                  </Link>
                </div>
              )}
            </>
          )}
          {viewType === 'map' && (
            <>
              {userUploaded.length !== 0 ? (
                <MapView propertyData={userUploaded} />
              ) : (
                <div style={dataCenter}>
                  You don't have any property listed.{' '}
                  <Link
                    to="/dashboard/property-add"
                    className="form-submit btn btn-sm btn-gradient-secondary">
                    Add your property now!
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </DashboardComponent>
  );
};
export default connect(null, {getUserProperties, deleteSavedProperties})(
  IsLoadingHOC(PropertyListing, 'Loading ...'),
);
