import React from 'react';
import {useField} from 'formik';
const Checkbox = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-group">
      <input
        type="checkbox"
        className="form-check-input"
        {...field}
        {...props}
      />
      <label
        htmlFor={props.id || props.name}
        className="form-check-label font-weight-bold text-dark">
        {label}
      </label>
      {/* {meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null} */}
    </div>
  );
};
export default Checkbox;
