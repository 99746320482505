import React, {useEffect, useState} from 'react';
import DashboardComponent from '../Dashboard.component';
import {Formik, Form, useField, Field} from 'formik';
import {
  Checkbox,
  MySelect,
  MyTextInput,
  Radio,
  SelectCountry,
  TextArea,
} from '../../../form';
import {connect} from 'react-redux';
import {Marker, GoogleMap} from '../../../common/map';
import schemaPropertyAdd from '../../../../validationSchema/addProperty';
import {currencyOption, optionsCountry} from '../../../../config/constant';
import {
  getCityData,
  getStateData,
  addNewProperty,
} from '../../../../redux/actions';
import ReactFileReader from 'react-file-reader';
import {toast} from 'react-toastify';
import Geocode from 'react-geocode';
import {defaultOptions} from '../../../../config';
import {useHistory} from 'react-router-dom';
import IsLoadingHOC from '../../../common/IsLoadingHOC';

import uploadIcon from '../../../../assets/images/Upload.svg';

const PropertyAdd = props => {
  const {amenities, setLoading} = props;
  const [lat, setLatitude] = useState(23.0572982);
  const [lng, setLongitude] = useState(72.685513);
  const [zoom, setZoom] = useState(10);
  const [data, setData] = useState({
    country: '',
    state: '',
    city: '',
    address1: '',
    address2: '',
  });
  const [location, setLocation] = useState('');
  const [pre_address, setpre_address] = useState('');
  const [sec_address, setsec_address] = useState('');
  const [zipCode, setzip_code] = useState('');
  const [street_address, setStreet_address] = useState('');
  const [coverError, setCoverError] = useState('');
  const [floorPicError, setFloorPicError] = useState('');
  const [fileName, setFileName] = useState('');
  const history = useHistory();
  Geocode.setApiKey(defaultOptions.googleGeoLocationKey);
  const handleCountry = selectedOption => {
    getStateData(selectedOption);
  };
  const handleState = selectedOption => {
    getCityData(selectedOption);
  };
  const getCityData = id => {
    props.getCityData({
      stateId: id,
    });
  };
  const getStateData = id => {
    props.getStateData({
      countryId: id,
    });
  };
  useEffect(() => {
    const value =
      data.country +
      ' ' +
      data.state +
      ' ' +
      data.city +
      ' ' +
      data.address1 +
      ' ' +
      data.address2;
    Geocode.fromAddress(value).then(
      response => {
        const {lat, lng} = response.results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
      },
      error => {
        console.error(error);
      },
    );
  }, [data]);
  // useEffect(()=>{
  const geoAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      response => {
        const address = response.results[0].formatted_address;
        let city,
          state,
          country,
          pre_address,
          sec_address,
          zip_code,
          street_Address;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'locality':
                city = response.results[0].address_components[i].long_name;

                break;
              case 'administrative_area_level_1':
                state = response.results[0].address_components[i].long_name;
                break;
              case 'country':
                country = response.results[0].address_components[i].long_name;
                break;
              case 'premise':
                pre_address =
                  response.results[0].address_components[i].long_name;
                setpre_address(pre_address);
                break;
              case 'sublocality_level_2':
                sec_address =
                  response.results[0].address_components[i].long_name;
                setsec_address(sec_address);
                break;
              case 'route':
              case 'street_address':
                street_Address =
                  response.results[0].address_components[i].long_name;
                setStreet_address(street_Address);
                break;
              case 'postal_code':
                zip_code = response.results[0].address_components[i].long_name;
                setzip_code(zip_code);
                break;
            }
          }
        }
      },
      error => {
        // console.error(error);
      },
    );
  };
  // },[lat, lng])
  return (
    <DashboardComponent>
      <Formik
        initialValues={{
          property_for: '',
          title: '',
          property_type: '',
          currency: '',
          price: '',
          country: '',
          city: '',
          state: '',
          address1: '',
          address2: '',
          description: '',
          propertyFeature: {
            number_of_beds: '',
            number_of_baths: '',
            propertyArea: '',
            areaType: '',
          },
          amenities: [],
          floorPlans: [],
          video: '',
          video360: '',
          latitude: '',
          longitude: '',
          terms_acceptance: false,
          cover: [],
        }}
        validationSchema={schemaPropertyAdd}
        onSubmit={(values, {setSubmitting}) => {
          setLoading(true);
          var data = new FormData();
          data.append('country', values.country);
          data.append('property_for', values.property_for);
          data.append('title', values.title);
          data.append('property_type', values.property_type);
          data.append('currency', values.currency);
          data.append('price', values.price);
          data.append('state', values.state);
          data.append('city', values.city);
          if (values.cover.name === undefined || values.cover.name === '') {
            setCoverError('No Cover Image Selected');
            setLoading(false);
            return;
          } else {
            setCoverError('');
            data.append('cover', values.cover);
          }
          if (pre_address !== undefined) {
            data.append('address1', values.address1 + ' ' + pre_address);
          } else {
            data.append('address1', values.address1);
          }
          data.append('address2', values.address2);
          data.append('longitude', lat);
          data.append('latitude', lng);
          values.amenities.map(item => {
            data.append('amenities', item);
          });
          data.append('description', values.description);
          data.append('number_of_beds', values.propertyFeature.number_of_beds);
          data.append(
            'number_of_baths',
            values.propertyFeature.number_of_baths,
          );
          data.append('propertyArea', values.propertyFeature.propertyArea);
          data.append('areaType', values.propertyFeature.areaType);
          if (values.floorPlans.length > 0) {
            for (let i = 0; i < values.floorPlans.length; i++) {
              data.append('floorPlans', values.floorPlans[i]);
              setFloorPicError('');
            }
          } else {
            setLoading(false);
            setFloorPicError('No Property pictures Selected');
            return;
          }
          console.log('values', values);

          if (values.video) {
            data.append('video', values.video);
          }
          if (values.video360) {
            data.append('_360View', values.video360);
          }
          data.append('terms_acceptance', values.terms_acceptance);
          props
            .addNewProperty(data)
            .then(
              response => {
                setLoading(false);
                setSubmitting(false);
                toast.success('Property Added Successfully');
                history.push('/dashboard/property-listing');
              },
              error => {
                setLoading(false);
                toast.success(error.message[0]);
              },
            )
            .catch(error => {
              setSubmitting(false);
              toast.success('Something Went Worrg');
            });
        }}>
        {({values, errors, touched, setFieldValue}) => (
          <Form>
            <div
              className="tab-pane"
              id="company-nav-tab-add-property"
              role="tabpanel"
              aria-labelledby="company-nav-tab-add-property-tab">
              <div className="text-lg-left p-4 p-lg-5 has-box-shadow">
                <div className="row">
                  <div className="col-lg-2 col-12">
                    <label className="mb-lg-0 mb-3">Sale Type</label>
                  </div>
                  <div className="col-lg-9 col-12">
                    <div className="form-group">
                      <div className="form-check form-check-inline mr-3">
                        <Radio
                          name="property_for"
                          label="Buy"
                          id="interested_in_buy"
                          value="BUY"
                        />
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <Radio
                          name="property_for"
                          label="Rent"
                          id="interested_in_rent"
                          value="RENT"
                        />
                      </div>
                      {errors?.property_for && (
                        <div className="error">{errors.property_for}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row my-3">
                  <div className="col-lg-6 col-12">
                    <MyTextInput
                      label="Property Name"
                      name="title"
                      type="text"
                      placeholder="Enter Property Name"
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <MySelect
                      name="property_type"
                      label="Select Property Type"
                      className="form-control secondary-select">
                      <option value=""></option>
                      {props.propertyTypes?.map(item => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.type_title}
                          </option>
                        );
                      })}
                    </MySelect>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-lg-6 col-12">
                    <MySelect
                      name="currency"
                      label="Currency"
                      className="form-control secondary-select">
                      <option>Select Currency</option>
                      {currencyOption.map(item => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </MySelect>
                  </div>
                  <div className="col-lg-6 col-12">
                    <MyTextInput
                      label="Price"
                      name="price"
                      type="text"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-lg-6 col-12">
                    <MySelect
                      name="country"
                      label="Country"
                      className="form-control secondary-select"
                      onChange={e => {
                        var index = e.target.selectedIndex;
                        var optionElement = e.target.childNodes[index];
                        var option = optionElement.getAttribute('id');
                        handleCountry(option);
                        setFieldValue('country', e.target.value);
                        setData({...data, country: e.target.value});
                        setZoom(8);
                      }}>
                      <option value="0">Select Country</option>
                      {optionsCountry.map(item => {
                        return (
                          <option
                            key={item.label}
                            id={item.value}
                            value={item.label}>
                            {item.label}
                          </option>
                        );
                      })}
                    </MySelect>
                  </div>
                  <div className="col-lg-6 col-12">
                    <MySelect
                      name="state"
                      label="State"
                      className="form-control secondary-select"
                      onChange={e => {
                        var index = e.target.selectedIndex;
                        var optionElement = e.target.childNodes[index];
                        var option = optionElement.getAttribute('id');
                        handleState(option);
                        setFieldValue('state', e.target.value);
                        setData({...data, state: e.target.value});
                        setZoom(6);
                      }}>
                      <option value="0">Select State</option>

                      {props.stateData.map(item => {
                        return (
                          <option
                            key={item.label}
                            id={item.value}
                            value={item.label}>
                            {item.label}
                          </option>
                        );
                      })}
                    </MySelect>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-lg-6 col-12">
                    <MySelect
                      name="city"
                      label="City"
                      className="form-control secondary-select"
                      onChange={e => {
                        var index = e.target.selectedIndex;
                        var optionElement = e.target.childNodes[index];
                        var option = optionElement.getAttribute('id');
                        setFieldValue('city', e.target.value);
                        setData({...data, city: e.target.value});
                        setZoom(12);
                      }}>
                      <option value="0">Select City</option>

                      {props.cityData.map(item => {
                        return (
                          <option
                            key={item.label}
                            id={item.value}
                            value={item.label}>
                            {item.label}
                          </option>
                        );
                      })}
                    </MySelect>
                  </div>
                </div>

                <div className="row my-3">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <div style={{height: '60vh', width: '100%'}}>
                          <GoogleMap
                            lat={lat}
                            lng={lng}
                            zoom={zoom}
                            onDragStart={() => {
                              setLocation('');
                            }}
                            onDragEnd={() => {
                              setLocation(pre_address);
                              setFieldValue(
                                'address2',
                                location +
                                  ' ' +
                                  street_address +
                                  ' ,' +
                                  zipCode,
                              );
                            }}
                            onChange={({center}) => {
                              geoAddress(center.lat, center.lng);
                            }}>
                            <Marker
                              lat={lat}
                              lng={lng}
                              text={'Kreyser Avrora'}
                            />
                          </GoogleMap>
                          {/* <DummyMap/> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-lg-6 col-12">
                    <MyTextInput
                      className="form-control secondary-input"
                      label="Address Line 1"
                      name="address1"
                      type="text"
                      placeholder="Enter Address"
                      onChange={e => {
                        setFieldValue('address1', e.target.value);
                        setData({
                          ...data,
                          address1: e.target.value + pre_address,
                        });
                        setZoom(18);
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <MyTextInput
                      className="form-control secondary-input"
                      label="Address Line 2"
                      name="address2"
                      type="text"
                      placeholder="Enter Address"
                      onChange={e => {
                        setFieldValue('address2');
                        // setData({ ...data, address2: e.target.value })
                        setZoom(18);
                      }}
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-12">
                    <TextArea
                      label="Property Description"
                      name="description"
                      placeholder="Enter Description"
                      rows="5"
                      id="description"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-12">
                    <label className="ml-2">Property Features</label>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-lg-4 col-md-6 col-12 my-lg-0 my-2">
                    <MyTextInput
                      label="No. of Beds"
                      type="number"
                      name="propertyFeature.number_of_beds"
                      placeholder="Enter Number of beds"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 my-lg-0 my-2">
                    <MyTextInput
                      label="No. of Baths"
                      type="number"
                      name="propertyFeature.number_of_baths"
                      placeholder="Enter Number of baths"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 my-lg-0 my-2">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-lg-6">
                          <MyTextInput
                            label="Total Property Area"
                            name="propertyFeature.propertyArea"
                            type="number"
                            placeholder="Enter Area"
                          />
                        </div>
                        <div className="col-lg-6 my-lg-0 my-2">
                          <MySelect
                            label="Area Type"
                            className="form-control secondary-select"
                            name="propertyFeature.areaType">
                            <option>Select Area</option>
                            <option>Sq. ft</option>
                            <option>Sq. m</option>
                            <option>acre</option>
                          </MySelect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-12">
                    <label className="ml-2">Property Amenities</label>
                  </div>
                </div>
                <div className="row my-4">
                  {amenities.map(item => {
                    return (
                      <div
                        className="col-lg-3 col-md-6 col-12 mb-lg-0 my-3"
                        key={item._id}>
                        <div className="form-check">
                          <Checkbox
                            name="amenities"
                            label={item.feature}
                            id={item._id}
                            value={item._id}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {errors?.amenities && (
                  <div className="error">{errors.amenities}</div>
                )}

                <div className="row my-3">
                  <div className="col-lg-12 col-12 my-lg-0 my-2">
                    <label className="my-4">
                      Add Property Photos ( Main Cover Photo )
                    </label>
                    <label className="dashed-border p-4" htmlFor="coverphoto">
                      <div className="image-upload">
                        <img
                          alt=""
                          className="mx-auto cursor"
                          src={uploadIcon}
                          style={{width: '30px'}}
                        />
                        <p className="mt-3">
                          {values.cover.name === undefined ? (
                            'Add Property Cover Photo'
                          ) : (
                            <span>{values.cover.name}</span>
                          )}
                        </p>
                        <input
                          name="cover"
                          type="file"
                          id="coverphoto"
                          accept="image/*"
                          className="custom-file-input d-none"
                          onChange={e =>
                            setFieldValue('cover', e.target.files[0])
                          }
                        />
                        {coverError.length > 0 && (
                          <div className="error">
                            {values.cover.name === undefined ? coverError : ''}
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="row row my-3">
                  <div className="col-12">
                    <label className="my-4">
                      Add More Photos ( Adding More Photos can boost the lead )
                    </label>
                    <label className="dashed-border p-4" htmlFor="more-photos">
                      <div className="image-upload">
                        <img
                          alt=""
                          className="mx-auto cursor"
                          src={uploadIcon}
                          style={{width: '30px'}}
                        />
                        <p className="mt-3">
                          {values.floorPlans.length === 0
                            ? 'Add More Photos'
                            : values.floorPlans.length + ' images Selected'}
                        </p>
                        <MyTextInput
                          name="floorPlans"
                          type="file"
                          id="more-photos"
                          name="more-photos"
                          accept="image/*"
                          className="custom-file-input d-none"
                          multiple
                          onChange={e =>
                            setFieldValue('floorPlans', e.currentTarget.files)
                          }
                        />
                        {floorPicError.length > 0 && (
                          <div className="error">
                            {values.floorPlans.length === 0
                              ? floorPicError
                              : ''}
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                </div>

                <div className="row my-3">
                  <div className="col-lg-6 col-12 my-lg-0 my-2">
                    <label className="my-4">Video Upload</label>
                    <label className="dashed-border p-4" htmlFor="video">
                      <div className="image-upload">
                        <img
                          alt=""
                          className="mx-auto cursor"
                          src={uploadIcon}
                          style={{width: '30px'}}
                        />
                        <p className="mt-3">
                          {values.video.name === undefined ? (
                            'Video Upload'
                          ) : (
                            <span>{values.video.name}</span>
                          )}
                        </p>
                        <MyTextInput
                          type="file"
                          name="file[]"
                          id="video"
                          className="custom-file-input d-none"
                          accept="video/*"
                          onChange={e => {
                            setFieldValue('video', e.target.files[0]);
                          }}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="col-lg-6 col-12 my-lg-0 my-2">
                    <label className="my-4">360 Video Upload</label>
                    <label className="dashed-border p-4" htmlFor="360-video">
                      <div className="image-upload">
                        <img
                          alt=""
                          className="mx-auto cursor"
                          src={uploadIcon}
                          style={{width: '30px'}}
                        />
                        <p className="mt-3">
                          {!values.video360.name ? (
                            '360 Video Upload'
                          ) : (
                            <span>{values.video360.name}</span>
                          )}
                        </p>
                        <MyTextInput
                          type="file"
                          name="file[]"
                          id="360-video"
                          accept="image/*"
                          className="custom-file-input d-none"
                          onChange={e => {
                            console.log('video360', e.target.files[0]);
                            setFieldValue('video360', e.target.files[0]);
                          }}
                        />
                      </div>
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7 col-12 my-2">
                    <div className="form-check">
                      <Checkbox
                        name="terms_acceptance"
                        label="Agree to terms and conditions"
                        id="termsAndConditions"
                      />
                      {errors?.terms_acceptance && (
                        <div className="error">{errors.terms_acceptance}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      id="signup-button"
                      type="submit"
                      className="btn btn-gradient-secondary w-100 my-5"
                      //onClick="changeActiveTab('company-nav-tab-dashboard');"
                    >
                      Add Property
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </DashboardComponent>
  );
};
const mapStateToProps = ({app}) => {
  const {
    configData: {amenities, propertyTypes},
    stateData,
    cityData,
  } = app;
  return {
    amenities,
    propertyTypes,
    stateData,
    cityData,
  };
};

export default connect(mapStateToProps, {
  getCityData,
  getStateData,
  addNewProperty,
})(IsLoadingHOC(PropertyAdd, 'Loading .....'));
