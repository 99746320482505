import React from "react";
import DashboardComponent from "../Dashboard.component";
const Wallet = () => {
	return (
		<DashboardComponent>
			<div className="tab-pane fade active show" id="user-dashboard-nav-tab-my-wallet" role="tabpanel" aria-labelledby="user-dashboard-nav-tab-my-wallet-tab">
				<div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow has-border-radius">
					<div className="row d-lg-flex align-items-center justify-content-center">
						<div className="col-xl-3 col-lg-6 col-12">
							<div className="p-4 has-border-radius has-box-shadow bg-gradient my-xl-0 my-lg-2 my-2">
								<p>AQR Tokens</p>
								<h3 className="font-weight-bold">5000 tokens</h3>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-12">
							<div className="p-4 has-border-radius has-box-shadow text-white my-xl-0 my-lg-2 my-2 bg-gradient-secondary">
								<p>Token Code</p>
								<h3 className="font-weight-bold">A#$DL853</h3>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-12">
							<div className="p-4 has-border-radius has-box-shadow my-xl-0 my-lg-2 my-2 bg-gradient">
								<p>Token Price</p>
								<h3 className="font-weight-bold">200 AED</h3>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-12">
							<div className="p-4 has-border-radius has-box-shadow my-xl-0 my-lg-2 my-2 text-white bg-gradient-secondary">
								<p>Tokens</p>
								<h3 className="font-weight-bold">50</h3>
							</div>
						</div>
					</div>
					<div className="row my-6">
						<div className="col-lg-12 col-12">
							<h3 className="mb-lg-3 mb-3">Create Token Wallet</h3>
						</div>
						<div className="col-lg-4 col-md-12 col-12 my-lg-0 my-2">
							<button className="btn btn-gradient-secondary w-100">Import Private Key</button>
						</div>
						<div className="col-lg-4 col-md-12 col-12 my-lg-0 my-2">
							<button className="btn btn-gradient-secondary w-100">Generate Private Key</button>
						</div>
					</div>
					<div className="row my-6">
						<div className="col-lg-12 col-12">
							<h3 className="mb-lg-3 mb-3">Wallet Created</h3>
						</div>
						<div className="col-lg-6 col-md-12 col-12 my-lg-0 my-2">
							<input className="form-control secondary-input" type="password" id="create-transaction-password" placeholder="Create Transaction Password"
								// style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACIUlEQVQ4EX2TOYhTURSG87IMihDsjGghBhFBmHFDHLWwSqcikk4RRKJgk0KL7C8bMpWpZtIqNkEUl1ZCgs0wOo0SxiLMDApWlgOPrH7/5b2QkYwX7jvn/uc//zl3edZ4PPbNGvF4fC4ajR5VrNvt/mo0Gr1ZPOtfgWw2e9Lv9+chX7cs64CS4Oxg3o9GI7tUKv0Q5o1dAiTfCgQCLwnOkfQOu+oSLyJ2A783HA7vIPLGxX0TgVwud4HKn0nc7Pf7N6vV6oZHkkX8FPG3uMfgXC0Wi2vCg/poUKGGcagQI3k7k8mcp5slcGswGDwpl8tfwGJg3xB6Dvey8vz6oH4C3iXcFYjbwiDeo1KafafkC3NjK7iL5ESFGQEUF7Sg+ifZdDp9GnMF/KGmfBdT2HCwZ7TwtrBPC7rQaav6Iv48rqZwg+F+p8hOMBj0IbxfMdMBrW5pAVGV/ztINByENkU0t5BIJEKRSOQ3Aj+Z57iFs1R5NK3EQS6HQqF1zmQdzpFWq3W42WwOTAf1er1PF2USFlC+qxMvFAr3HcexWX+QX6lUvsKpkTyPSEXJkw6MQ4S38Ljdbi8rmM/nY+CvgNcQqdH6U/xrYK9t244jZv6ByUOSiDdIfgBZ12U6dHEHu9TpdIr8F0OP692CtzaW/a6y3y0Wx5kbFHvGuXzkgf0xhKnPzA4UTyaTB8Ph8AvcHi3fnsrZ7Wore02YViqVOrRXXPhfqP8j6MYlawoAAAAASUVORK5CYII=&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;"

							/>
						</div>
						<div className="col-lg-6 col-md-12 col-12 my-lg-0 my-2">
							<input className="form-control secondary-input" type="password" id="confirm-transaction-password" placeholder="Confirm Transaction Passowrd" 
								//style={background-image : url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACIUlEQVQ4EX2TOYhTURSG87IMihDsjGghBhFBmHFDHLWwSqcikk4RRKJgk0KL7C8bMpWpZtIqNkEUl1ZCgs0wOo0SxiLMDApWlgOPrH7/5b2QkYwX7jvn/uc//zl3edZ4PPbNGvF4fC4ajR5VrNvt/mo0Gr1ZPOtfgWw2e9Lv9+chX7cs64CS4Oxg3o9GI7tUKv0Q5o1dAiTfCgQCLwnOkfQOu+oSLyJ2A783HA7vIPLGxX0TgVwud4HKn0nc7Pf7N6vV6oZHkkX8FPG3uMfgXC0Wi2vCg/poUKGGcagQI3k7k8mcp5slcGswGDwpl8tfwGJg3xB6Dvey8vz6oH4C3iXcFYjbwiDeo1KafafkC3NjK7iL5ESFGQEUF7Sg+ifZdDp9GnMF/KGmfBdT2HCwZ7TwtrBPC7rQaav6Iv48rqZwg+F+p8hOMBj0IbxfMdMBrW5pAVGV/ztINByENkU0t5BIJEKRSOQ3Aj+Z57iFs1R5NK3EQS6HQqF1zmQdzpFWq3W42WwOTAf1er1PF2USFlC+qxMvFAr3HcexWX+QX6lUvsKpkTyPSEXJkw6MQ4S38Ljdbi8rmM/nY+CvgNcQqdH6U/xrYK9t244jZv6ByUOSiDdIfgBZ12U6dHEHu9TpdIr8F0OP692CtzaW/a6y3y0Wx5kbFHvGuXzkgf0xhKnPzA4UTyaTB8Ph8AvcHi3fnsrZ7Wore02YViqVOrRXXPhfqP8j6MYlawoAAAAASUVORK5CYII=&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; }
							/>
						</div>
					</div>
					<div className="row my-6">
						<div className="col-lg-4 col-md-6 col-12">
							<button className="btn btn-gradient-secondary w-100" 
								// onclick="changeActiveTab('user-dashboard-nav-tab-transaction-history')"
							>Transaction History</button>
						</div>
						<div className="col-lg-8 col-md-6 col-12 d-flex justify-content-md-start justify-content-center my-lg-0 my-2">
							<ul className="list-group list-group-horizontal transactions text-center">
								<li className="list-group-item">
									<img alt="" src="/images/icon-my-wallet-dashboard-deposit.svg"/>
									<span><p className="my-1">Deposit</p></span>
								</li>
								<li className="list-group-item">
									<img alt="" src="/images/icon-my-wallet-dashboard-withdrawal.svg"/>
									<span><p className="my-1">Withdrawal</p></span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</DashboardComponent>
	);
};
export default Wallet;
