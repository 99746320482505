import React from 'react';
import Aqarverified from '../../common/AqarVarified';
import Image from '../../common/ProjectComponent/Image';
import {defaultOptions} from '../../../config';

const PropertySlider = ({data}) => {
  return (
    <section id="property-detail-hero">
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-8 text-center">
            <div id="property-detail-hero-image" className="mb-4 mb-lg-0">
              {/* {data.hasOwnProperty('propPics') > 0 ? ( */}
              <a
                href={`${defaultOptions.baseUrl}/${data.cover}`}
                data-fancybox="property-detail-hero-gallery"
                data-caption="Property Hero">
                <Image url={data.cover} />
              </a>
              {/* ) : null} */}

              {data.aqarVerified ? <Aqarverified /> : null}
            </div>
          </div>
          <div className="col-lg-4 text-center">
            <div id="property-detail-hero-gallery">
              <div id="property-detail-hero-gallery-images">
                {data.hasOwnProperty('floorPlans') > 0
                  ? data.floorPlans.map((item, index) => {
                      return (
                        <a
                          key={index}
                          className="property-detail-hero-gallery-image"
                          href={`${defaultOptions.baseUrl}/${item}`}
                          data-fancybox="property-detail-hero-gallery"
                          data-caption="Property Detail #1">
                          <Image url={item} />
                        </a>
                      );
                    })
                  : ''}
              </div>
              <div
                id="property-detail-hero-gallery-find-more"
                data-fancybox-trigger="property-detail-hero-gallery">
                <h2 className="text-white font-weight-bold mb-0">
                  + Find More
                </h2>
              </div>
            </div>
            <div id="property-detail-share" className="mt-3 mt-lg-4 mb-3">
              <div className="d-flex justify-content-between">
                <p className="mr-auto mb-0">Share Via</p>
                <div id="property-detail-share-icons" className="">
                  <a href="#abc" title="Share via Facebook" className="">
                    <img src="/images/icon-share-facebook.svg" alt="Facebook" />
                  </a>
                  <a href="#abc" title="Share via Twitter" className="">
                    <img src="/images/icon-share-twitter.svg" alt="Twitter" />
                  </a>
                  <a href="#abc" title="Share via Whatsapp" className="">
                    <img src="/images/icon-share-whatsapp.svg" alt="Whatsapp" />
                  </a>
                  <a href="#abc" title="Share via Email" className="">
                    <img src="/images/icon-share-email.svg" alt="Email" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertySlider;
