import React, {useState, useEffect} from 'react';
import DashboardComponent from '../Dashboard.component';
import {
  getUserProfile,
  getCityData,
  getStateData,
  updatProfile,
  updatProfileCompany,
  updatProfileAgent,
} from '../../../../redux/actions';
import {connect, useSelector} from 'react-redux';
import {Country, State, City, isLoadingHOC} from '../../../common';
import {toast} from 'react-toastify';
const Profile = props => {
  const {
    mobile,
    email,
    country_code,
    first_name,
    last_name,
    country,
    city,
    state,
    role,
    stateData,
    cityData,
  } = props;

  const x = useSelector(x => x);

  console.log('propspropsprops', props);

  const [editProfile, setEditProfile] = useState(false);
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);
  const [countryName, setCountryName] = useState(country);
  const [cityName, setCity] = useState(city);
  const [stateName, setStateName] = useState(state);

  const toggleProfileView = () => {
    setEditProfile(!editProfile);
  };

  useEffect(() => {
    props.getUserProfile();
  }, []);

  const setCountry = selectedOption => {
    console.log(selectedOption);
    getStateData(selectedOption.value);
    setCountryName(selectedOption.label);
  };
  const setStateValue = selectedOption => {
    getCityData(selectedOption.value);
    setStateName(selectedOption.label);
  };
  const getCityData = id => {
    props.getCityData({
      stateId: id,
    });
  };
  const getStateData = id => {
    props.getStateData({
      countryId: id,
    });
  };

  const handleSubmit = () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      country: countryName,
      state: stateName,
      city: cityName,
    };
    const dataOwner = {
      first_name: firstName,
      last_name: lastName,
      country: countryName,
      email: email,
      state: stateName,
      city: cityName,
    };
    const {setLoading} = props;
    setLoading(true);
    if (role === 'USER') {
      props
        .updatProfile(dataOwner)
        .then(
          response => {
            toast.success(response.message);
            setEditProfile(!editProfile);
            setLoading(false);
          },
          error => {
            setEditProfile(!editProfile);
            setLoading(false);
          },
        )
        .catch(error => {
          toast.error(error);
          setEditProfile(!editProfile);
          setLoading(false);
        });
    } else if (role === 'COMPANY') {
      props
        .updatProfileCompany(data)
        .then(
          response => {
            toast.success(response.message);
            setEditProfile(!editProfile);
            setLoading(false);
          },
          error => {
            setEditProfile(!editProfile);
            setLoading(false);
          },
        )
        .catch(error => {
          toast.error(error);
          setEditProfile(!editProfile);
          setLoading(false);
        });
    } else if (role === 'AGENT') {
      props
        .updatProfileAgent(data)
        .then(response => {
          toast.success(response.message);
          setEditProfile(!editProfile);
          setLoading(false);
        })
        .catch(error => {
          toast.error(error);
          setEditProfile(!editProfile);
          setLoading(false);
        });
    }
  };
  let user = useSelector(state => state.app.user);
  return (
    <DashboardComponent>
      <div
        className={`tab-pane ${
          !editProfile ? 'my-profile-view' : ''
        } show active`}
        id="company-nav-tab-my-profile"
        role="tabpanel"
        aria-labelledby="company-nav-tab-my-profile-tab">
        <div className="text-lg-left p-4 p-lg-5 has-box-shadow has-border-radius">
          <div className="row d-lg-flex align-items-center">
            <div className="col-lg-6 col-12">
              <h3 className="mb-3 mb-lg-3 font-weight-bold text-lg-left text-center">
                My Profile
              </h3>
            </div>
            <div className="col-lg-6 col-12 text-right">
              <div
                className={`edit-button ${editProfile ? 'd-none' : ''}`}
                id="edit-profile-btn"
                onClick={toggleProfileView}>
                <span>Edit</span>
                <img alt="" src="public/images/icon-edit.svg" />
              </div>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="first-name">First Name</label>
                <input
                  onChange={e => setFirstName(e.target.value)}
                  value={firstName}
                  type="text"
                  className="form-control secondary-input"
                  id="first-name"
                  placeholder="Enter First Name"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="last-name">Last Name</label>
                <input
                  onChange={e => setLastName(e.target.value)}
                  value={lastName}
                  type="text"
                  className="form-control secondary-input"
                  id="last-name"
                  placeholder="Enter Last Name"
                />
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6 col-12">
              <label>Mobile Number</label>
              <div className="form-group form-inline">
                <select
                  readOnly
                  className="form-control secondary-select mr-lg-2"
                  id="mobile-no-prepend">
                  <option readOnly>+{country_code}</option>
                </select>
                <input
                  value={mobile}
                  readOnly
                  type="text"
                  className="form-control secondary-input"
                  id="mobile-no"
                  placeholder="Enter Mobile Number"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="email-id">Email ID</label>
                <input
                  value={email}
                  readOnly
                  type="email"
                  className="form-control secondary-input"
                  id="email-id"
                  placeholder="Enter Email ID"
                />
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="country">Country</label>
                {editProfile ? (
                  <Country onChange={setCountry} />
                ) : (
                  <div>{country}</div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="state">State</label>
                {editProfile ? (
                  <State
                    onChange={selectedOption => setStateValue(selectedOption)}
                    data={stateData}
                  />
                ) : (
                  <div>{state}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="city">City</label>
                {editProfile ? (
                  <City
                    onChange={selectedOption => setCity(selectedOption.label)}
                    data={cityData}
                  />
                ) : (
                  <div>{city}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-4 col-md-6 col-12">
              <button
                className={`btn btn-gradient-secondary ${
                  !editProfile ? 'd-none' : ''
                } w-100`}
                id="save-profile-btn"
                onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardComponent>
  );
};
const mapStateToProps = ({app}) => {
  const {
    first_name,
    last_name,
    email,
    country,
    city,
    mobile,
    country_code,
    state,
    role,
  } = app.user;
  const {stateData, cityData} = app;
  return {
    first_name,
    last_name,
    email,
    country,
    city,
    state,
    country_code,
    mobile,
    role,
    stateData,
    cityData,
  };
};
export default connect(mapStateToProps, {
  getCityData,
  getStateData,
  getUserProfile,
  updatProfile,
  updatProfileCompany,
  updatProfileAgent,
})(isLoadingHOC(Profile, 'Waiting .....'));
