import React from 'react';
import GoogleMapReact from 'google-map-react';
import {defaultOptions} from '../../../config';
import {Link} from 'react-router-dom';

const MapView = ({propertyData}) => {
  const dataCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    minHeight: '500px',
  };
  return (
    <div id="search-results-map">
      <div className="row">
        <div className=" col-12">
          <div
            style={{
              height: '500px',
              width: '100%',
              padding: '4px',
              backgroundColor: '#ccc',
            }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: defaultOptions.googleGeoLocationKey,
              }}
              defaultZoom={5}
              defaultCenter={{lat: 25, lng: 50}}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({map, maps}) =>
                propertyData.map(i => {
                  new maps.Marker({
                    position: {
                      lat: i.location.coordinates[0],
                      lng: i.location.coordinates[1],
                    },
                    map,
                    title: i.title,
                  });
                })
              }></GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapView;
