import React from 'react';
import {useField} from 'formik';
const TextArea = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name} className="ml-2">
        {label}
      </label>
      <textarea
        className="form-control secondary-input"
        {...field}
        {...props}></textarea>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
export default TextArea;
