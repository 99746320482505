import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import {defaultOptions} from '../../../config';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

const GoogleMap = ({children, ...props}) => {
  return (
    <Wrapper>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: defaultOptions.googleGeoLocationKey,
          libraries: ['places'],
        }}
        defaultZoom={11}
        center={{
          lat: children.props?.lat,
          lng: children.props?.lng,
        }}
        defaultCenter={{lat: children.props?.lat, lng: children.props?.lng}}
        {...props}>
        {children}
      </GoogleMapReact>
    </Wrapper>
  );
};

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
