import {aqarChain} from '../../aqarchain';

export const addQuery = data => async dispatch => {
  return new Promise(async (resolve, reject) => {
    const response = await aqarChain.post('/api/enquiry/addQuery', data);
    if (response.status === 1) {
      resolve(response);
    } else {
      reject(response);
    }
  });
};
