import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {likeProperty} from '../../redux/actions';
import IsLoadingHOC from './IsLoadingHOC';

const PropertyLike = props => {
  const {item, token, setLoading, likeProperty} = props;
  const [likeData, setLikeData] = useState({
    count: item.likes,
  });
  const location = useLocation();

  const handleLikeClick = id => {
    setLoading(true);
    likeProperty({prop_id: id})
      .then(
        response => {
          setLoading(false);
          toast.success(response.message);
          setLikeData({count: item.likes + 1});
        },
        error => {
          setLoading(false);
          toast.success(error.message);
        },
      )
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <div
      className="property-like"
      style={{
        display: 'flex',
        marginRight: '1rem',
        alignItems: 'flex-end',
        gap: '0.75rem',
      }}>
      {location.pathname === '/search-view' && (
        <p style={{margin: 0}}>{likeData.count}</p>
      )}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.73 25.62"
        style={{width: '29px'}}
        id="icon-list_property-like"
        fill={item.liked ? '#FBB43C' : 'black'}
        onClick={() =>
          token
            ? handleLikeClick(item._id)
            : toast.success('Please Login to Like Property')
        }>
        <title>Like</title>
        <g id="icon-list_property-like-1" data-name="Layer 1">
          <g id="icon-list_property-like-2" data-name="Layer 2">
            <path d="M26.73,15.31A2.47,2.47,0,0,0,26,13.56a2.77,2.77,0,0,0,.71-2.12,2.88,2.88,0,0,0-2.91-2.53H16.93a15.64,15.64,0,0,0,.89-4.45C17.82,2,15.77,0,14.48,0a3.48,3.48,0,0,0-2,.68.55.55,0,0,0-.21.43V4.89L9,11.84l-.13.07v-.22a.56.56,0,0,0-.56-.55H2.78A2.79,2.79,0,0,0,0,13.92v8.91a2.79,2.79,0,0,0,2.78,2.79H6.13a2.8,2.8,0,0,0,2.62-1.84,7.31,7.31,0,0,0,2.95.72H21.92a2.55,2.55,0,0,0,2.52-1.94,2.45,2.45,0,0,0-.2-1.67,2.52,2.52,0,0,0,1.38-2.23,2.49,2.49,0,0,0-.26-1.12A2.49,2.49,0,0,0,26.73,15.31Zm-2.35,1.38a.55.55,0,0,0-.46.38.57.57,0,0,0,.14.58,1.33,1.33,0,0,1,.44,1A1.38,1.38,0,0,1,23.27,20a.56.56,0,0,0-.47.38A.57.57,0,0,0,23,21a1.37,1.37,0,0,1,.4,1.33,1.43,1.43,0,0,1-1.43,1.07H11.7a5.24,5.24,0,0,1-2.95-.95.55.55,0,0,0-.61-.12.54.54,0,0,0-.34.51A1.67,1.67,0,0,1,6.13,24.5H2.78a1.67,1.67,0,0,1-1.67-1.67V13.92a1.67,1.67,0,0,1,1.67-1.67h5v.56a.56.56,0,0,0,.26.47.57.57,0,0,0,.54,0l1.12-.56a.57.57,0,0,0,.25-.26l3.34-7.24A.53.53,0,0,0,13.37,5V1.42a2.3,2.3,0,0,1,1.11-.31c.61,0,2.23,1.52,2.23,3.35a17.29,17.29,0,0,1-1.08,4.81.55.55,0,0,0,.52.75h7.66a1.77,1.77,0,0,1,1.8,1.52,1.66,1.66,0,0,1-.79,1.57.6.6,0,0,0-.27.5.57.57,0,0,0,.3.47,1.38,1.38,0,0,1-.47,2.61Z" />
            <path d="M8.35,12.25a.56.56,0,0,0-.55.56v10a.56.56,0,1,0,1.11,0v-10A.56.56,0,0,0,8.35,12.25Z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

const mapStateToProps = ({app}) => {
  const {token} = app;
  return {
    token,
  };
};
export default connect(mapStateToProps, {likeProperty})(
  IsLoadingHOC(PropertyLike),
);
