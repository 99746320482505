import React from "react";
import Select from "react-select";
const City = ({data, onChange, selected}) => {
	return (
		<Select className="select-box" 
			value={selected}
			options={data}
			placeholder="Select City"
			onChange={(e) => onChange(e)}
		/>
	);
};

export default City;
