import React from 'react';
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class Section2 extends React.Component {
  render() {
    return (
      <section id="landing-section_2">
        <div id="landing-section_2-market_leader" className="container-lg">
          <div className="row no-gutters">
            <div className="col-lg-6 col-12 text-center text-lg-left">
              <img
                className="img-fluid w-90"
                alt=""
                src="/images/market_leader.png"
              />
            </div>
            <div className="col-lg-6 col-12 text-center text-md-left">
              <div className="p-4 p-md-6 pr-md-9 mx-3 has-box-shadow bg-dots-variant-1 bg-position-top-right-offset bg-repeat-no-repeat">
                <OwlCarousel className="owl-carousel owl-theme" loop items={1}>
                  <div className="item">
                    <h1 className="font-weight-bold mb-3">
                      We’re Market Leader in Middle East
                    </h1>
                    <p className="text-justify">
                      Aqarchain is an online property portal whose mission is to
                      provide an unmatched experience in the search for a home
                      on rent, remove the middleman and end the need for the
                      site visit.
                    </p>
                    <p className="text-justify">
                      We have a team of ambitious people, poised to make
                      Aqarchain a reputable brand for renting, selling, and
                      buying a home in the Middle East & UAE.
                    </p>
                  </div>
                  <div className="item">
                    <h1 className="font-weight-bold mb-3">Our Vision</h1>
                    <p className="text-justify">
                      Our vision is to facilitate and encourage people to invest
                      in the best properties for them in the UAE & Gulf region
                      through its ecosystem.
                    </p>
                  </div>
                </OwlCarousel>
                <Link className="nav-link" to="/search-view">
                  <button className="btn btn-gradient-secondary btn-rounded">
                    Explore Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Section2;
