import React from 'react';
import {connect} from 'react-redux';
import Section1 from '../SearchView/Section1';
class FilterSection extends React.Component {
  render() {
    return (
      <>
        <section key="11212" id="landing-section_1">
          <div id="landing-section_1-banner" className="container-lg">
            <div className="row no-gutters">
              <div className="col-lg-3 text-center text-lg-left">
                <h1 className="mb-3 mb-lg-0 ml-lg-4">
                  A New Way to Explore{' '}
                  <span className="font-weight-bold">Real Estate</span>
                </h1>
              </div>
              <div className="col-lg-8 offset-lg-1 text-center text-lg-left">
                <div className="show-inline-container">
                  <div className="show-inline">
                    <img
                      src="/images/logo-expo_2020_dubai_uae.png"
                      alt="Expo 2020 - Dubai, United Arab Emirates"
                    />
                  </div>
                  <div className="show-inline">
                    <img
                      src="/images/logo-vision_2021_uae.png"
                      alt="Vision 2021 - United Arab Emirates"
                    />
                  </div>
                  <div className="show-inline">
                    <img
                      src="/images/logo-vision_2030_ksa.png"
                      alt="Vision 2030 - Kingdom of Saudi Arabia"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="landing-section_1-form" className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-1">
                <Section1 />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {app} = state;
  //console.log("dattttt", app);
  return {
    config: app.configData,
  };
};

export default connect(mapStateToProps, {})(FilterSection);
