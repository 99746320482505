import {defaultOptions} from '../config';
import {store} from '../redux/store';

class AqarChain {
  post(path, data = null) {
    return this.send(path, 'POST', data);
  }

  put(path, data = null) {
    return this.send(path, 'PUT', data);
  }

  get(path, data = null) {
    return this.send(path, 'GET', data);
  }

  delete(path, data = null) {
    return this.send(path, 'DELETE');
  }

  multipart(path, data) {
    return this.send(path, 'POST', data, true);
  }

  send(url, method, data, formType = false) {
    //let uri = `${this.base_url}${this.root_path}${url}`;
    //console.log('data', data);
    let language = store.getState('app').app.language;
    let {token, varifyToken} = store.getState('app').app;
    let uri = `${defaultOptions.baseUrl}${url}`;

    //`"Content-Type":  "multipart/form-data"`

    const headers = {
      //APIKEY: `${defaultOptions.apikey}`,
      //'Content-Type': 'multipart/form-data',
      localization: language ? language : 'en',
    };

    if (!formType) {
      headers['Content-Type'] = 'application/json';
    }
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (varifyToken) {
      headers.Otp_token = `Bearer ${varifyToken}`;
    }
    return new Promise((resolve, reject) => {
      console.log({
        uri,
        method,
        headers,
        data,
      });
      let option = '';
      if (method === 'GET') {
        option = {method, headers};
      } else {
        option = {
          method,
          headers,
          body: formType ? data : JSON.stringify(data),
        };
      }
      // console.log("option", option);

      fetch(uri, option)
        .then(response => {
          // console.log("response", response);
          if (response.ok) {
            return response.json();
          }
          // Possible 401 or other network error
          // console.log("status", response.status);
          if (response.status === 500 || response.status === 404) {
            let customResponse = {
              status: 0,
              data: {
                error: ['Something went wrong'],
              },
            };
            return customResponse;
            // reject(new Error(customResponse));
          } else if (response.status === 400 || response.status === 401) {
            return response.json();
          }
          return response
            .json()
            .then(errorResponse => Promise.reject(errorResponse));
        })
        .then(responseData => {
          // debugger;
          // console.log("getUserSavedProperties",responseData);
          resolve(responseData);
        })
        .catch(error => {
          // console.log("error", error);
          //const customError = this.getErrorMessageForResponce(error);
          reject('Something went wrong');
          //reject(error);
        });
    });
  }
}

export const aqarChain = new AqarChain();
