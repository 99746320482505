import React from "react";
import {Link, useLocation} from "react-router-dom";

const Footer = () => {
	const location = useLocation();
	return (
		<>
			<footer className="footer footer-bg text-center">
				<div className="container-lg">
					<div className="row">
						<div className="col-lg-2 col-md-12 col-12 footer-logo-bg">
							<img
								className="nav-brand"
								src={require("../../assets/images/logo.svg")}
								alt="website"
								width="145px"
								height="145px"
							/>
						</div>
						<div className="col-lg-3 col-md-6 col-12 yellow-border-right">
							<div className="d-inline-block text-lg-left p-4">
								<h1 className="text-white mt-3">Contact Info</h1>
								<p className="text-white">
                  E. <span className="ml-2">info@aqarchain.com</span>
								</p>
							</div>
						</div>
						<div className="col-lg-2 col-md-6 col-12 white-border-right">
							<div className="d-inline-block mt-3 text-lg-left p-4">
								<ul className="nav flex-column">
									<li className="nav-item">
										<a href="#abc" className="footer-link nav-link">
                      About us
										</a>
									</li>
									<li className="nav-item">
										<a href="#abc" className="footer-link nav-link">
                      Blogs
										</a>
									</li>
									<li className="nav-item">
										<Link
											className="footer-link nav-link"
											to="/company-registration">
                      Company Registration
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2 col-md-6 col-12">
							<div className="d-block mt-3 text-lg-left p-4">
								<ul className="nav flex-column">
									<li className="nav-item">
										<Link to="/privacy-policy" className="footer-link nav-link">
                      Privacy Policy
										</Link>
									</li>
									<li className="nav-item">
										<a href="#abc" className="footer-link nav-link text-nowrap">
                      Terms and Conditions
										</a>
									</li>
									<li className="nav-item">
										<a href="#abc" className="footer-link nav-link">
                      Career
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-12 text-lg-left text-center">
							<div className="p-4 mt-4">
								<ul className="nav justify-content-center">
									<li className="nav-item">
										<a href="#abc" className="nav-link">
											<img
												alt=""
												className="footer-image"
												src={require("../../assets/images/expo-2020-footer.svg")}
											/>
										</a>
									</li>
									<li className="nav-item ml-4">
										<a href="#abc" className="nav-link">
											<img
												alt=""
												className="footer-image"
												src={require("../../assets/images/vision-2030-footer.svg")}
											/>
										</a>
									</li>
									<li className="nav-item ml-4">
										<a href="#abc" className="nav-link">
											<img
												alt=""
												className="footer-image"
												src={require("../../assets/images/vision-2021-footer.png")}
											/>
										</a>
									</li>
								</ul>
								<p className="text-white copyright-text pl-lg-4">
                  ©️ Smart Chain Information Technology Consultancy Ltd. 2020 -
                  2021
								</p>
							</div>
						</div>
					</div>
					{location.pathname === "/" && (
						<div className="container footer_bottom">
							<div className="row">
								<div className="col-lg-2 text-center"></div>
								<div className="col-md-8">
									<p className="mb-3 text-left">Disclosure</p>
									<p className="text-justify">
                    Aqarchain is a 100% subsidiary of Smart Chain Information
                    Technology Consultancy Ltd. We have been exploring the
                    blockchain space in the middle east and it has turned out to
                    be one of the first adopters in the world. The UAE has been
                    working towards a strategic future vision in terms of
                    adopting technology and creating a regulatory framework.
                    Thus, we have created Aqarchain, a real estate asset
                    tokenization Platform, using Blockchain technology. An asset
                    tokenization platform that enables fractional Ownership.
									</p>
									<p className="text-justify">
                    Aqarchain Ltd. (proposed entity name to be incorporated in
                    DIFC) is in its inception stage and is inviting early stage
                    investors to be a part of the community through its Native
                    token sale (AQR). Aqarchain is applying for a crowdfunding
                    license and exploring options to be regulated at DIFC.
									</p>
								</div>
								<div className="col-lg-2 text-center"></div>
							</div>
						</div>
					)}
				</div>
			</footer>
		</>
	);
};

export default Footer;
