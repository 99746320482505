import React, {useState} from 'react';
import {connect} from 'react-redux';
import FullName from '../../common/FullName';
import {addReview} from '../../../redux/actions';
import IsLoadingHOC from '../../common/IsLoadingHOC';
import {toast} from 'react-toastify';

const Rating = ({close, addReview, item, setLoading}) => {
  const [count, setCount] = useState(3);
  const [review, setReview] = useState();
  // const [images, setImages] = React.useState();
  // const [finalImages, setFinalImages] = useState();
  // const onChange = (imageList, addUpdateIndex) => {
  //   // data for submit
  //   console.log(imageList, addUpdateIndex);
  //   const imageListFinal = imageList.map(item => {
  //     return item.file;
  //   });
  //   console.log('final test', imageList);
  //   setImages(imageList);
  //   setFinalImages(imageListFinal);
  // };
  // const maxNumber = 5;

  const onSubmit = () => {
    // console.log('review-modal', count, review);

    const data = {
      stars: count,
      property_id: item._id,
      description: review,
      // images: finalImages,
    };
    setLoading(true);
    addReview(data)
      .then(response => {
        setLoading(false);
        toast.success(response.message);
        close();
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
        toast.error(error);
        close();
      });
  };
  return (
    <div className="modal review-modal fade show" style={{display: 'block'}}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button onClick={() => close()} type="button" className="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body px-5 py-2 text-center">
            <div className="row mb-3">
              <div className="col-lg-2 col-12 my-lg-0 my-3 d-flex align-items-center justify-content-lg-start justify-content-center">
                <img
                  className="reviewer-image"
                  src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                  alt="avatar"
                />
              </div>
              <div className="col-lg-10 col-12 text-lg-left my-auto">
                <h2 className="font-weight-bold mb-1">
                  <FullName />
                </h2>
                <p className="review-disclaimer mb-0">
                  Your review will be posted publicly on the web
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-2 col-12 text-lg-left">
                <div className="review-rating cursor-pointer pr-2 mr-2 pr-lg-3 mr-lg-3 my-lg-0 my-2">
                  <img
                    onClick={() => setCount(1)}
                    src={require(`../../../assets/images/icon-star${
                      count >= 1 ? '-active' : ''
                    }.svg`)}
                    alt=""
                  />
                  <img
                    onClick={() => setCount(2)}
                    src={require(`../../../assets/images/icon-star${
                      count >= 2 ? '-active' : ''
                    }.svg`)}
                    alt=""
                  />
                  <img
                    onClick={() => setCount(3)}
                    src={require(`../../../assets/images/icon-star${
                      count >= 3 ? '-active' : ''
                    }.svg`)}
                    alt=""
                  />
                  <img
                    onClick={() => setCount(4)}
                    src={require(`../../../assets/images/icon-star${
                      count >= 4 ? '-active' : ''
                    }.svg`)}
                    alt=""
                  />
                  <img
                    onClick={() => setCount(5)}
                    src={require(`../../../assets/images/icon-star${
                      count >= 5 ? '-active' : ''
                    }.svg`)}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-lg-10 offset-lg-2 col-12 text-lg-left">
                <div className="form-group">
                  <label className="ml-2" htmlFor="description">
                    Describe your experience
                  </label>
                  <textarea
                    onChange={e => setReview(e.target.value)}
                    className="form-control secondary-input"
                    rows="3"
                    id="description"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="col-lg-3 offset-lg-6 col-12">
              <button
                type="button"
                onClick={onSubmit}
                className="btn btn-gradient-secondary w-100 btn-rounded">
                Submit
              </button>
            </div>
            <div className="col-lg-3 col-12">
              <button
                onClick={() => close()}
                type="button"
                className="btn btn-gradient w-100 btn-rounded">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({app}) => {
  const {userData} = app;
  return {
    userData,
  };
};
export default connect(mapStateToProps, {addReview})(
  IsLoadingHOC(Rating, 'Waiting.....'),
);
