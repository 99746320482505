import React from 'react';
const TableBox = ({children}) => {
  return (
    <table className="dashboard-table">
      <thead>
        <tr>
          <th>Property ID</th>
          <th>Property Title</th>
          <th>Property Type</th>
          <th>Company Name</th>
          <th>Price</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default TableBox;
